import React from "react";
import { useTranslation } from 'react-i18next';
import useScrollAnimation from "../../hooks/useScrollAnimation";
export default function Legal() {
    const { t } = useTranslation();
    useScrollAnimation();
    return (
        <>
            <section className="hearder-pages relative border-b-4 border-b-[#0093dd]">
                <div className="btmAni30002 h-full text-white flex items-center justify-center f-title text-5xl font-bold uppercase relative gap-[20px]">
                    <div className="flex flex-col gap-[30px]">
                        <span className="ang-1 w-[20px] h-[20px] flex bg-transparent border-t border-l border-t-[1px] border-l-[1px] border-transparent border-t-white border-l-white"></span>
                        <span className="ang-2 w-[20px] h-[20px] flex bg-transparent border-b border-b border-b-[1px] border-l-[1px] border-transparent border-b-white border-l-white"></span>
                    </div>
                    {t("Legal")}
                    <div className="flex flex-col gap-[30px]">
                        <span className="ang-3 w-[20px] h-[20px] flex bg-transparent border-t border-r border-t-[1px] border-r-[1px] border-transparent border-t-white border-r-white"></span>
                        <span className="ang-4 w-[20px] h-[20px] flex bg-transparent border-b border-r border-b-[1px] border-r-[1px] border-transparent border-b-white border-r-white"></span>
                    </div>
                </div>
                <div className="d-none animtion-pages absolute -left-[300px] -top-[36px] transition-all duration-500">
                    <img src={require("../../assest/images/animation.png")} alt="" />
                </div>
            </section>
            <section className="relative py-[40px]">
                <div className="container flex flex-col gap-[40px]">
                    <div className="flex flex-col gap-[50px]">
                        <div className="border-left-none btmAni30002 font-bold text-white text-[42px] f-29 uppercase border-l-[12px] border-l-[#0093dd] pl-[10px]">{t("Legal")}</div>
                        <div className="btmAni25002 padding-right-10 text-white font-light text-[25px] pl-[10px]">
                            {t("legal content")}
                        </div>
                    </div>
                    <div className="flex flex-col gap-[10px]">
                        <div className="flex items-center gap-[10px]">
                            <div className="js-scroll fade-left bgLinearTwo w-[50px] h-[50px] flex items-center justify-center text-[32px] text-white font-bold rounded-[5px]">A</div>
                            <div className="js-scroll fade-right font-bold text-white text-[42px] f-29 uppercase">{t("Legal requirements")}</div>
                        </div>
                        <div className="pl-[60px] text-white font-light text-[25px] flex flex-col gap-[15px]">
                            <p className="js-scroll fade-btm">
                                {t("a")}
                            </p>
                            <p className="js-scroll fade-btm">
                                {t("aa")}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col  gap-[10px]">
                        <div className="flex items-center gap-[10px]">
                            <div className="js-scroll fade-left bgLinearTwo w-[50px] h-[50px] flex items-center justify-center text-[32px] text-white font-bold rounded-[5px]">B</div>
                            <div className="js-scroll fade-right font-bold text-white text-[42px] f-29 uppercase">{t("Legal Notice")}</div>
                        </div>
                        <div className="pl-[60px] text-white font-light text-[25px] flex flex-col gap-[15px]">
                            <p className="js-scroll fade-btm">
                                {t("b")}
                            </p>
                            <p className="js-scroll fade-btm">
                                {t("bb")}
                            </p>
                            <p className="js-scroll fade-btm">
                                {t("bbb")}
                            </p>
                            <p className="js-scroll fade-btm">
                                {t("bbbb")}
                            </p>
                            <p className="js-scroll fade-btm">
                                {t("bbbbb")}
                            </p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}