import React from "react";
import { useTranslation } from 'react-i18next';
import clientsData from "../data/clientsData";
import useScrollAnimation from "../../hooks/useScrollAnimation";
export default function Clients() {
    const { t } = useTranslation();

    useScrollAnimation();
    const showData = clientsData.map((el,index) =>
        <div key={index} className="js-scroll fade-btm w-[275px] h-[275px] bgLinearTwo p-[25px] rounded-[10px]">
            <img className="w-[224px] h-[225px]" src={el.image} alt="" />
        </div>)
    return (
        <>
            <section className="hearder-pages relative border-b-4 border-b-[#0093dd]">
                <div className="btmAni30002 h-full text-white flex items-center justify-center f-title text-5xl font-bold uppercase relative gap-[20px]">
                    <div className="flex flex-col gap-[30px]">
                        <span className="ang-1 w-[20px] h-[20px] flex bg-transparent border-t border-l border-t-[1px] border-l-[1px] border-transparent border-t-white border-l-white"></span>
                        <span className="ang-2 w-[20px] h-[20px] flex bg-transparent border-b border-b border-b-[1px] border-l-[1px] border-transparent border-b-white border-l-white"></span>
                    </div>
                    {t("Clients & References")}
                    <div className="flex flex-col gap-[30px]">
                        <span className="ang-3 w-[20px] h-[20px] flex bg-transparent border-t border-r border-t-[1px] border-r-[1px] border-transparent border-t-white border-r-white"></span>
                        <span className="ang-4 w-[20px] h-[20px] flex bg-transparent border-b border-r border-b-[1px] border-r-[1px] border-transparent border-b-white border-r-white"></span>
                    </div>
                </div>
                <div className="d-none animtion-pages absolute -left-[300px] -top-[36px] transition-all duration-500">
                    <img src={require("../../assest/images/animation.png")} alt="" />
                </div>
            </section>
            <section className="relative py-[40px]">
                <div className="container flex flex-col gap-[30px]">
                    <div className="flex flex-col gap-[50px]">
                        <div className="border-left-none btmAni25002 font-bold text-white text-[42px] f-29 uppercase border-l-[12px] border-l-[#0093dd] pl-[10px]">{t("Our Clients & References")}</div>
                        <div className="btmAni20002 padding-right-10 text-white font-light text-[25px] pl-[10px]">
                            {t("client content")}
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-[25px] items-center justify-center pl-[10px]">
                        {showData}
                    </div>
                </div>
            </section>
        </>
    );
}