const sliderImage = [
    {
        id: 1,
        image : require("../../assest/slider/1 (1).png"),
    },
    {
        id: 2,
        image : require("../../assest/slider/1 (2).png")
    },
    {
        id: 3,
        image : require("../../assest/slider/1 (3).png")
    },
    {
        id: 4,
        image : require("../../assest/slider/1 (4).png")
    },
    
];

export default sliderImage;
