// ScrollToTop.js
import React, { useEffect, useState } from "react";
import { FaAngleUp } from "../assest/icons/icons"; // Using react-icons for Font Awesome icon

const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.scrollY >= 400) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <div className="goTop" style={{ display: isVisible ? "block" : "none" }} onClick={scrollToTop}>
            <span className="bgLinearTwo">
                <FaAngleUp />
            </span>
        </div>
    );
};

export default ScrollToTop;
