import React from "react";
import { useTranslation } from 'react-i18next';
// import "https://fonts.googleapis.com/css2?family=Charter:wght@400;700&display=swap";
import useScrollAnimation from "../../hooks/useScrollAnimation";
export default function Ceo() {
    const { t } = useTranslation();

    useScrollAnimation();

    return (
        <>
            <section className="relative py-[40px]">
                <div className="container text-white flex flex-col gap-[30px]">
                    <div className="btmAni30002 flex flex-col gap-[20px] items-center text-white font-bold text-[30px]">
                        <div>
                            <img src={require("../../assest/images/ceo.svg").default} alt="" />
                        </div>
                        <div className="btmAni25002">{t("Chairman")}</div>
                    </div>
                    <div className="flex flex-col gap-[30px]">
                        <div className="btmAni20002 text-center text-[20px]" style={{ fontFamily: 'Charter, serif', fontStyle: "italic" }}>
                            {t('Welcome to Hi-Tech Company, a leader in advanced security systems and aviation solutions for over 30 years.')}
                        </div>
                        <div className="btmAni3000 text-center text-[20px]" style={{ fontFamily: 'Charter, serif', fontStyle: "italic" }}>
                            {t("ceo1")}
                        </div>
                        <div className="js-scroll fade-btm text-center text-[20px]" style={{ fontFamily: 'Charter, serif', fontStyle: "italic" }}>
                            {t("ceo2")}
                        </div>
                        <div className="js-scroll fade-btm text-center text-[20px]" style={{ fontFamily: 'Charter, serif', fontStyle: "italic" }}>
                            {t("ceo3")}
                        </div>
                        <div className="js-scroll fade-btm text-center text-[20px]" style={{ fontFamily: 'Charter, serif', fontStyle: "italic" }}>
                            {t("ceo4")}
                        </div>
                        <div className="js-scroll fade-btm text-center text-[20px]" style={{ fontFamily: 'Charter, serif', fontStyle: "italic" }}>
                            {t("Thank you for trusting Hi-Tech Company")}
                        </div>
                    </div>
                    <div>
                        <div className="js-scroll fade-btm text-[24px] font-bold">{t("Founder & CEO")} </div>
                        <div className="js-scroll fade-btm text-[24px] font-bold">{t("Mazen Queisi")}</div>
                        <img className="js-scroll fade-btm w-[100px] h-[100px]" src={require("../../assest/images/signitsher.svg").default} alt="" />
                    </div>
                </div>
            </section>

        </>
    );
}