// src/hooks/useScrollAnimation.js
import { useEffect } from "react";

const useScrollAnimation = () => {
    useEffect(() => {
        const scrollElements = document.querySelectorAll(".js-scroll");

        const elementInView = (el, dividend = 1) => {
            const elementTop = el.getBoundingClientRect().top;
            return (
                elementTop <=
                (window.innerHeight || document.documentElement.clientHeight) / dividend
            );
        };

        const elementOutofView = (el) => {
            const elementTop = el.getBoundingClientRect().top;
            return (
                elementTop > (window.innerHeight || document.documentElement.clientHeight)
            );
        };

        const displayScrollElement = (element) => {
            element.classList.add("scrolled");
        };

        const hideScrollElement = (element) => {
            element.classList.remove("scrolled");
        };

        const handleScrollAnimation = () => {
            scrollElements.forEach((el) => {
                if (elementInView(el, 1.25)) {
                    displayScrollElement(el);
                } else if (elementOutofView(el)) {
                    hideScrollElement(el);
                }
            });
        };

        window.addEventListener("scroll", handleScrollAnimation);
        handleScrollAnimation(); // Run animation on initial load

        return () => {
            window.removeEventListener("scroll", handleScrollAnimation);
        };
    }, []);
};

export default useScrollAnimation;
