const clientsData = [
    {
        id: 1,
        image: require("../../assest/client/1 (1).jpg"),
    },
    {
        id: 2,
        image: require("../../assest/client/1 (1).png"),
    },
    {
        id: 3,
        image: require("../../assest/client/1 (10).png"),
    },
    {
        id: 4,
        image: require("../../assest/client/1 (10).jpg"),
    },
    {
        id: 5,
        image: require("../../assest/client/1 (11).jpg"),
    },
    {
        id: 6,
        image: require("../../assest/client/1 (11).png"),
    },
    {
        id: 7,
        image: require("../../assest/client/1 (12).jpg"),
    },
    {
        id: 8,
        image: require("../../assest/client/1 (12).png"),
    },
    {
        id: 9,
        image: require("../../assest/client/1 (2).jpg"),
    },
    {
        id: 10,
        image: require("../../assest/client/1 (2).png"),
    },{
        id: 11,
        image: require("../../assest/client/1 (3).jpg"),
    },
    {
        id: 12,
        image: require("../../assest/client/1 (3).png"),
    },
    {
        id: 13,
        image: require("../../assest/client/1 (4).jpg"),
    },
    {
        id: 14,
        image: require("../../assest/client/1 (4).png"),
    },
    {
        id: 15,
        image: require("../../assest/client/13.jpg"),
    },
    {
        id: 16,
        image: require("../../assest/client/1 (9).jpg"),
    },
    {
        id: 18,
        image: require("../../assest/client/1 (8).png"),
    },
    {
        id: 19,
        image: require("../../assest/client/1 (8).jpg"),
    },
    {
        id: 20,
        image: require("../../assest/client/1 (7).png"),
    },

    {
        id: 21,
        image: require("../../assest/client/1 (7).jpg"),
    },
    {
        id: 22,
        image: require("../../assest/client/1 (6).png"),
    },
    {
        id: 23,
        image: require("../../assest/client/1 (6).jpg"),
    },
    {
        id: 24,
        image: require("../../assest/client/1 (5).png"),
    },
    {
        id: 25,
        image: require("../../assest/client/1 (5).jpg"),
    },
    
];

export default clientsData;
