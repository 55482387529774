import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import useScrollAnimation from "../../hooks/useScrollAnimation";

export default function Care() {
    const { t } = useTranslation();

    // Form State
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        contact: '',
        newsOffers: false,
        privacyPolicy: false,
    });
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    useScrollAnimation();

    // Handle Input Changes
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    // Validate Form Data
    const validateForm = () => {
        if (!formData.firstName || !formData.lastName || !formData.email || !formData.phone) {
            return t("Please fill out all required fields.");
        }
        if (!formData.privacyPolicy) {
            return t("You must agree to the Privacy Policy.");
        }
        return null;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        setSuccess(false);

        // Form validation
        const validationError = validateForm();
        if (validationError) {
            setError(validationError);
            return;
        }

        try {
            // Submit form data to PHP script
            const response = await fetch("https://email.petra-ms.com/care.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: new URLSearchParams(formData).toString(),
            });

            // Check if the response is ok
            if (response.ok) {
                setSuccess(true);
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    contact: '',
                    newsOffers: false,
                    privacyPolicy: false,
                });

                // Add a delay before redirecting to the external site
                setTimeout(() => {
                    window.location.href = "https://hitechdetection.com/"; // Redirect to the external website
                }, 1000); // Delay of 1 second before redirection

            } else {
                const result = await response.text(); // or response.json() if the response is JSON
                throw new Error(result || t("Failed to submit the form."));
            }
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <>
            <section className="hearder-pages relative border-b-4 border-b-[#0093dd]">
                <div className="btmAni30002 h-full text-white flex items-center justify-center f-title text-5xl font-bold uppercase relative gap-[20px]">
                    <div className="flex flex-col gap-[30px]">
                        <span className="ang-1 w-[20px] h-[20px] flex bg-transparent border-t border-l border-t-[1px] border-l-[1px] border-transparent border-t-white border-l-white"></span>
                        <span className="ang-2 w-[20px] h-[20px] flex bg-transparent border-b border-b border-b-[1px] border-l-[1px] border-transparent border-b-white border-l-white"></span>
                    </div>
                    {t("We Care")}
                    <div className="flex flex-col gap-[30px]">
                        <span className="ang-3 w-[20px] h-[20px] flex bg-transparent border-t border-r border-t-[1px] border-r-[1px] border-transparent border-t-white border-r-white"></span>
                        <span className="ang-4 w-[20px] h-[20px] flex bg-transparent border-b border-r border-b-[1px] border-r-[1px] border-transparent border-b-white border-r-white"></span>
                    </div>
                </div>
                <div className="d-none animtion-pages absolute -left-[300px] -top-[36px] transition-all duration-500">
                    <img src={require("../../assest/images/animation.png")} alt="" />
                </div>
            </section>
            <section className="relative py-[40px]">
                <div className="container">
                    <form onSubmit={handleSubmit}>
                        <div className="flex flex-col gap-[20px]">
                            <div className="flex flex-col gap-[20px]">
                                <div className="f-col g-15 flex items-center justify-between">
                                    <div className="js-scroll fade-right width-100 flex flex-col gap-[7px] w-[500px]">
                                        <label className="text-[20px] font-bold text-white capitalize">{t("First Name")}</label>
                                        <input
                                            className="outline-0 border border-[#0093DD] p-2 bg-transparent text-white w-full rounded-lg"
                                            type="text"
                                            placeholder={t("First Name")}
                                            name="firstName"
                                            value={formData.firstName}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="js-scroll fade-left width-100 flex flex-col gap-[7px] w-[500px]">
                                        <label className="text-[20px] font-bold text-white capitalize">{t("Last Name")}</label>
                                        <input
                                            className="outline-0 border border-[#0093DD] p-2 bg-transparent text-white w-full rounded-lg"
                                            type="text"
                                            placeholder={t("Last Name")}
                                            name="lastName"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="f-col g-15 flex items-center justify-between">
                                    <div className="js-scroll fade-right width-100 flex flex-col gap-[7px] w-[500px]">
                                        <label className="text-[20px] font-bold text-white capitalize">{t("Email")}</label>
                                        <input
                                            className="outline-0 border border-[#0093DD] p-2 bg-transparent text-white w-full rounded-lg"
                                            type="email"
                                            placeholder={t("Email")}
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="js-scroll fade-left width-100 flex flex-col gap-[7px] w-[500px]">
                                        <label className="text-[20px] font-bold text-white capitalize">{t("Phone Number")}</label>
                                        <input
                                            className="outline-0 border border-[#0093DD] p-2 bg-transparent text-white w-full rounded-lg"
                                            type="text"
                                            placeholder={t("Phone Number")}
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-[15px] capitalize">
                                    <div className="text-white font-bold text-[22px]">{t("Preferred Mode of Contact")}</div>
                                    <div className="space-y-4 text-white">
                                        <div className="flex items-center gap-[6px]">
                                            <label className="js-scroll fade-top flex items-center gap-[5px]">
                                                <input
                                                    type="radio"
                                                    name="contact"
                                                    value="Phone"
                                                    className="form-radio text-blue-600"
                                                    checked={formData.contact === 'Phone'}
                                                    onChange={handleChange}
                                                />
                                                <span>{t("Phone")}</span>
                                            </label>
                                            <label className="js-scroll fade-top flex items-center gap-[5px]">
                                                <input
                                                    type="radio"
                                                    name="contact"
                                                    value="Email"
                                                    className="form-radio text-blue-600"
                                                    checked={formData.contact === 'Email'}
                                                    onChange={handleChange}
                                                />
                                                <span>{t("Email")}</span>
                                            </label>
                                        </div>
                                        <label className="flex items-center gap-[10px]">
                                            <input
                                                type="checkbox"
                                                name="newsOffers"
                                                checked={formData.newsOffers}
                                                onChange={handleChange}
                                                className="form-checkbox"
                                            />
                                            <span>{t("Send me news & offers")}</span>
                                        </label>
                                        <label className="flex items-center gap-[10px]">
                                            <input
                                                type="checkbox"
                                                name="privacyPolicy"
                                                checked={formData.privacyPolicy}
                                                onChange={handleChange}
                                                className="form-checkbox"
                                            />
                                            <span>{t("I agree to the Privacy Policy")}</span>
                                        </label>
                                    </div>
                                </div>
                                {error && <p className="text-red-500 font-bold">{error}</p>}
                                {success && <p className="text-green-500 font-bold">{t("Form submitted successfully!")}</p>}
                                <div className="">
                                    <button
                                        type="submit"
                                        className="custom-btn btn-3"
                                    >
                                        <span>{t("send")}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
}
