import React, { useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import useScrollAnimation from "../../../hooks/useScrollAnimation";
export default function StatisticsSection() {

    const { t } = useTranslation();

    const [startCounter, setStartCounter] = useState(false);

    const { ref } = useInView({
        triggerOnce: true,
        threshold: 0.5,
        onChange: (inView) => {
            if (inView) {
                setStartCounter(true);
            }
        },
    });

    useScrollAnimation();

    return (
        <section className="statistics-section relative py-[40px]">
            <div
                ref={ref}
                className="container w-full h-full flex items-center gap-[50px] justify-center"
            >
                <div className="f-col flex items-center gap-[50px] justify-between">
                    <div className="flex flex-col items-center gap-[10px]">
                        <div className="js-scroll fade-top capitalize text-white font-bold text-[35px]">
                            {startCounter ? (
                                <CountUp end={30} duration={3} delay={0.5} />
                            ) : (
                                "0"
                            )}{" "}
                            + {t("Years")}
                        </div>
                        <div className="js-scroll fade-btm capitalize text-white font-bold text-[25px]">{t("Since 1993")}</div>
                    </div>
                    <div className="flex flex-col items-center gap-[10px]">
                        <div className="js-scroll fade-top capitalize text-white font-bold text-[35px]">
                            {startCounter ? (
                                <CountUp end={200} duration={3.5} delay={0.5} />
                            ) : (
                                "0"
                            )}{" "}
                            +
                        </div>
                        <div className="js-scroll fade-btm capitalize text-white font-bold text-[25px]">{t("projects")}</div>
                    </div>
                    <div className="flex flex-col items-center gap-[10px]">
                        <div className="js-scroll fade-top capitalize text-white font-bold text-[35px]">
                            {startCounter ? (
                                <CountUp
                                    end={3000000000}
                                    duration={4}
                                    delay={0.5}
                                    separator=","
                                />
                            ) : (
                                "0"
                            )}{" "}
                            +
                        </div>
                        <div className="js-scroll fade-btm capitalize text-white font-bold text-[25px]">{t("Cumulative Projects Value")}</div>
                    </div>
                </div>
            </div>
        </section>
    );
}
