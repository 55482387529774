// src/component/ScrollToTop.js

import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const Top = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top on route change
  }, [location]);

  return null;
};

export default Top;
