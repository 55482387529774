import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Link, useLocation } from "react-router-dom"; // Import useLocation for detecting route changes
import { Language } from "../../assest/icons/icons";

export default function Header() {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);

  const dropdownRef = useRef(null);
  const mobileMenuRef = useRef(null);
  const menuToggleRef = useRef(null);

  const location = useLocation(); // Get the current location

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuActive(!isMenuActive);
    setIsOpen(false);
  };

  const closeMenu = () => {
    setIsMenuActive(false);
  };

  i18n.on("languageChanged", (lng) => {
    document.documentElement.lang = lng;
    document.documentElement.dir = lng === "ar" ? "rtl" : "ltr";
  });

  // Close the menu on route change or reload
  useEffect(() => {
    closeMenu(); // Close the mobile menu when the route changes
  }, [location]);

  return (
    <header className="relative shadow-[0px_0px_10px_#ffffff9c] py-[10px] z-10">
      <div className="container flex items-center justify-between">
        <Link to={"/"}>
          <img src={require("../../assest/logo/logo.svg").default} alt="" />
        </Link>
        <nav className={`d-none flex-col gap-4 md:flex md:flex-row md:gap-0`}>
          <ul className="flex items-center gap-[20px]">
            {/* Desktop Links */}
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "text-[#0093DD] font-bold capitalize transition duration-500 border-b-2 border-[#0093DD] text-[19px]"
                    : "text-white font-bold capitalize transition duration-500 hover:border-b-2 border-[#0093DD] text-[19px] transition duration-500"
                }
                to="/"
              >
                {t("Home")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "text-[#0093DD] font-bold capitalize transition duration-500 border-b-2 border-[#0093DD] text-[19px]"
                    : "text-white font-bold capitalize transition duration-500 hover:border-b-2 border-[#0093DD] text-[19px] transition duration-500"
                }
                to="/about"
              >
                {t("About Us")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "text-[#0093DD] font-bold capitalize transition duration-500 border-b-2 border-[#0093DD] text-[19px]"
                    : "text-white font-bold capitalize transition duration-500 hover:border-b-2 border-[#0093DD] text-[19px] transition duration-500"
                }
                to="/services"
              >
                {t("Services")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "text-[#0093DD] font-bold capitalize transition duration-500 border-b-2 border-[#0093DD] text-[19px]"
                    : "text-white font-bold capitalize transition duration-500 hover:border-b-2 border-[#0093DD] text-[19px] transition duration-500"
                }
                to="/products"
              >
                {t("Products")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "text-[#0093DD] font-bold capitalize transition duration-500 border-b-2 border-[#0093DD] text-[19px]"
                    : "text-white font-bold capitalize transition duration-500 hover:border-b-2 border-[#0093DD] text-[19px] transition duration-500"
                }
                to="/portfolio"
              >
                {t("portfolio")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "text-[#0093DD] font-bold capitalize transition duration-500 border-b-2 border-[#0093DD] text-[19px]"
                    : "text-white font-bold capitalize transition duration-500 hover:border-b-2 border-[#0093DD] text-[19px] transition duration-500"
                }
                to="/client"
              >
                {t("Clients & References")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "text-[#0093DD] font-bold capitalize transition duration-500 border-b-2 border-[#0093DD] text-[19px]"
                    : "text-white font-bold capitalize transition duration-500 hover:border-b-2 border-[#0093DD] text-[19px] transition duration-500"
                }
                to="/contact"
              >
                {t("Contact Us")}
              </NavLink>
            </li>
          </ul>
        </nav>

        {/* Language Selector */}
        <div className="lang relative" ref={dropdownRef}>
          <button
            onClick={toggleDropdown}
            className="flex gap-2 items-center text-[20px] text-white py-2 px-10 rounded font-bold"
          >
            <Language />
            {t("Language")}
          </button>

          {isOpen && (
            <div className="w-full absolute top-full mt-2 bg-white shadow-md rounded flex flex-col items-center">
              <button
                className="bgLinear w-full h-[50px] text-white font-bold"
                onClick={() => changeLanguage("en")}
              >
                English
              </button>
              <button
                className="bgLinear w-full h-[50px] text-white font-bold"
                onClick={() => changeLanguage("ar")}
              >
                العربية
              </button>
            </div>
          )}
        </div>

        {/* Mobile Menu Toggle */}
        <button
          className={`menu ${isMenuActive ? "active" : ""}`}
          onClick={toggleMenu}
          ref={menuToggleRef}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>

        {/* Mobile Navigation */}
        {isMenuActive && (
          <nav
            ref={mobileMenuRef}
            className="absolute top-full left-0 right-0 bg-white p-4 shadow-md md:hidden flex flex-col items-center h-[500px] gap-[30px]"
          >
            <ul className="flex flex-col items-center gap-[20px]">
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "text-[#0093DD] font-bold capitalize transition duration-500 border-b-2 border-[#0093DD] text-[19px]"
                      : "text-black font-bold capitalize transition duration-500 hover:border-b-2 border-[#0093DD] text-[19px] transition duration-500"
                  }
                  to="/"
                  onClick={closeMenu} // Close menu on link click
                >
                  {t("Home")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "text-[#0093DD] font-bold capitalize transition duration-500 border-b-2 border-[#0093DD] text-[19px]"
                      : "text-black font-bold capitalize transition duration-500 hover:border-b-2 border-[#0093DD] text-[19px] transition duration-500"
                  }
                  to="/about"
                >
                  {t("About Us")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "text-[#0093DD] font-bold capitalize transition duration-500 border-b-2 border-[#0093DD] text-[19px]"
                      : "text-black font-bold capitalize transition duration-500 hover:border-b-2 border-[#0093DD] text-[19px] transition duration-500"
                  }
                  to="/services"
                >
                  {t("Services")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "text-[#0093DD] font-bold capitalize transition duration-500 border-b-2 border-[#0093DD] text-[19px]"
                      : "text-black font-bold capitalize transition duration-500 hover:border-b-2 border-[#0093DD] text-[19px] transition duration-500"
                  }
                  to="/products"
                >
                  {t("Products")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "text-[#0093DD] font-bold capitalize transition duration-500 border-b-2 border-[#0093DD] text-[19px]"
                      : "text-black font-bold capitalize transition duration-500 hover:border-b-2 border-[#0093DD] text-[19px] transition duration-500"
                  }
                  to="/portfolio"
                >
                  {t("portfolio")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "text-[#0093DD] font-bold capitalize transition duration-500 border-b-2 border-[#0093DD] text-[19px]"
                      : "text-black font-bold capitalize transition duration-500 hover:border-b-2 border-[#0093DD] text-[19px] transition duration-500"
                  }
                  to="/client"
                >
                  {t("Clients & References")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "text-[#0093DD] font-bold capitalize transition duration-500 border-b-2 border-[#0093DD] text-[19px]"
                      : "text-black font-bold capitalize transition duration-500 hover:border-b-2 border-[#0093DD] text-[19px] transition duration-500"
                  }
                  to="/contact"
                >
                  {t("Contact Us")}
                </NavLink>
              </li>
            </ul>
            {/* Language Selector */}
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={toggleDropdown}
                className="flex gap-2 items-center text-[20px] bg-[#0077b6] text-white py-2 px-10 rounded font-bold"
              >
                <Language />
                {t("Language")}
              </button>

              {isOpen && (
                <div className="w-full absolute top-full mt-2 bg-[#0077b6] shadow-md rounded flex flex-col items-center">
                  <button
                    className="bgLinear w-full h-[50px] text-white font-bold"
                    onClick={() => changeLanguage("en")}
                  >
                    English
                  </button>
                  <button
                    className="bgLinear w-full h-[50px] text-white font-bold"
                    onClick={() => changeLanguage("ar")}
                  >
                    العربية
                  </button>
                </div>
              )}
            </div>
          </nav>
        )}
      </div>
    </header>
  );
}
