const productData = [
    {
        id: 1,
        title: "B-SCAN",
        subTitle: "Transmission X-ray people screening",
        description: "The non-invasive B-SCAN people screening series detects objects and threats concealed in-or-on the body.",
        Overview: [
            "The B-SCAN series helps to reduce the smuggling of drugs, weapons, mobile phones and other contraband. It is available in four models, to support both general and limited-use applications, in accordance with ANSI N43.17 2009 guidelines. General-use refers to screening systems that use extremely low X-ray dose rates to screen a person.  The dose rate is so low that there is, in effect, no need to monitor the number of screenings an individual can have in a year.",
            "Limited use refers to screening systems that use a higher dose rate per scan to provide superior images. The use of these systems must be controlled and the annual scan count for a given person is limited. Scan and Image Management (SIM) software is used to fully log and control the use of the system so that any individual receives only the appropriate number of scans.",
            "Because B-SCAN uses fixed sensor components, it is a reliable and easy-to-maintain system, with low costs of ownership over its lifetime.There are currently several hundred B-SCAN systems in use around the world in a variety of applications, such as prisons, airports and customs checkpoints. Other applications include mines and refineries, helping to reduce incidents of diamond, gem and precious-metal thefts by employees.All models have been independently verified to meet the ANSI guidelines.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/BSCan1 (1).pdf",
            "../../../public/pdf/BSCan1 (2).pdf"
        ],
        Technical_Specification: [
            "Detects contraband and threat objects concealed in or on the body including weapons, explosives, narcotics, etc.",
            "high throughput - scan time less than 7 seconds",
            "Complete head-to-toe inspection even if shoes are worn",
            "Very low dose rates",
        ],
        images: [
            require("../../assest/products/B-Scan.png"),
            require("../../assest/products/B-Scan.png"),
            require("../../assest/products/B-Scan.png"),
        ],
        category: "Physical Barriers",
    },
    {
        id: 2,
        title: "BioFlash Biological Identifier",
        subTitle: "Mobile, high sensitivity biothreat detector",
        description: "The biothreat detector provides rapid, biological aerosol collection and identification in a compact unit.",
        Overview: [
            "The BioFlash® Biological Identifier is a bio-aerosol collection and identification system. It uses CANARY™ technology along with proprietary aerosol collection techniques to provide a low-risk, cost-effective biological sampling and identification solution for environmental monitoring.",
            "The BioFlash Biological Identifier provides rapid, sensitive and specific identification of various diseases and common biothreat agents such as anthrax, ricin, botulinum toxin, black plague, tularemia, smallpox. BioFlash® has also been proven to detect airborne SARS-CoV-2 by the United States Army Medical Research Institute of Infectious Diseases (USAMRIID)",
            "The high performance aerosol sampler of the BioFlash is portable, durable and capable of taking high sample volumes in short periods of time. These capabilities allow for both indoor and outdoor applications, so air quality can be tested quickly and easily wherever you are. The BioFlash can also be configured as a stand-alone device, used in tiered detection architectures to include a trigger and confirmatory sampler, or as an integrated component subsystem.",
            "The portable, compact and easy to use BioFlash Biological Identifier offers breakthrough capabilities in sampling performance. The BioFlash operates using BioDisk consumables that contain genetically engineered biosensors, expressing both the agent-specific antibodies and the calcium-sensitive bioluminescent molecules that are needed to produce light emission. Each BioDisk contains sixteen cuvettes with different agent-specific biosensors in specific cuvettes. This allows more than one agent of interest to be detected and identified.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/bio1.pdf",
            "../../../public/pdf/bio2.pdf"
        ],
        Technical_Specification: [
            "Rapid detection capability of airborne SARS-CoV-2",
            "Able to capture particles up to 10 microns in size",
            "Compact and lightweight design",
            "Easy to use and decontaminate",
            "PCR levels of sensitivity and specificity",
            "Extremely low false alarm rate",
            "Enables rapid response to new and emerging pathogens",
        ],
        images: [
            require("../../assest/products/Bio.png"),
            require("../../assest/products/Bio.png"),
            require("../../assest/products/Bio.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 3,
        title: "Checkpoint.Evoplus",
        subTitle: "Advanced screening and management platform",
        description: "Checkpoint.Evoplus helps individual components and sensors that are typically unable to communicate with each other and transforms them into a single, integrated and intelligent solution to advance the efficiency of your checkpoint.",
        Overview: [
            "Checkpoint.Evoplus is a digital-led solution that helps checkpoints achieve the highest level of security and improved operations by delivering critical insights and enabling remote screening.",
            "Checkpoint.Evoplus’   intelligence software analyses data from various systems, sensors and components from across an entire screening area to generate a full range of invaluable insights. This data makes it easy to monitor performance metrics in real-time for faster and better decision-making.",
            "Consolidated KPIs provide an overall view of the system status with clear audio and visual notifications drawing attention to any changes in the customised performance thresholds. It also generates historical data and reports required for resource planning and general administration. KPIs can be monitored and shared via a central dashboard which can also be accessed from mobile devices.",
            "Networked images can be collected from all security lanes and delivered to a team of operators based at a remote location, away from the distractions of the busy checkpoint. Suspicious areas are marked and classified on the images, so staff at the checkpoint know exactly where to focus secondary inspections.",
            "Checkpoint.Evoplus is compatible with both 2D and 3D screening, allowing for the benefits of the software to continue when upgrading to a 3D system, such as the HI-SCAN 6040 CTiX.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/checkpointsEvo1.pdf",
            "../../../public/pdf/checkpointsEvo2.pdf"
        ],
        Technical_Specification: [
            "Central screening and directed search",
            "Fully supports CT (3D) technology",
            "Aggregates data to deliver invaluable insights",
            "Faster decision-making and optimised resources",
            "Open interface for 3rd party integration",
        ],
        images: [
            require("../../assest/products/checkpoint.png"),
            require("../../assest/products/checkpoint.png"),
            require("../../assest/products/checkpoint.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 5,
        title: "CTX 5800",
        subTitle: "CT explosives detection system",
        description: "A compact explosives detection system (EDS), the CTX 5800 identifies threats in checked baggage and cargo packages.",
        Overview: [
            "ECAC Standard 3 approved and TSA certified, the CTX 5800 explosive detection system (EDS) quickly identifies threats in checked baggage.",
            "Using a single X-ray generator, the unit’s design provides high-resolution 3D images for quick and accurate threat detection in space or weight restricted environments.",
            "It is compact but flexible, and with simple extensions can process anything from large duffle bags to skis and golf bags, making it ideal for smaller airports. For large airports you might be interested in our high speed EDS CTX 9800.",
            "It can be fully integrated into a baggage handling system and offers networking solutions to link multiple scanners for efficient image distribution and system monitoring among operators.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/ctx5800.pdf",
        ],
        Technical_Specification: [
            "Threat detection in a small footprint",
            "Belt speed 0.14m/s (400-450BPH)",
            "Unlimited bag length (with curtain extensions)",
            "Stand-alone and in-line configurations",
            "Customized networking solutions",
            "High resolution 3D images from a single X-ray source",
        ],
        images: [
            require("../../assest/products/ctx.png"),
            require("../../assest/products/ctx.png"),
            require("../../assest/products/ctx.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 6,
        title: "CTX 9800 DSi",
        subTitle: "High speed CT explosives detection system",
        description: "ECAC Standard 3 approved and TSA certified explosives detection system (EDS) suitable for identifying threats in checked baggage and cargo packages.",
        Overview: [
            "The CTX 9800 DSi explosive detection system (EDS) uses a proprietary single X-ray source, dual energy design that provides high-resolution 3D images along with 2D and 3D organicinorganic material discrimination.",
            "These imaging tools enhance the analysis of details and specific structures of suspected threats, allowing for efficient security decisions on all checked bags and cargo packages.",
            "Built on a scalable platform with upgrade capabilities to meet future detection and capacity requirements, the CTX 9800 DSi can be integrated with any baggage-handling system.",
            "The system accepts large and elongated bags of up to 2.5m in length, reducing the need for separate processing of oversized luggage. Using a single conveyor belt, the CTX 9800 reduces bag slippage, and a single X-ray source reduces down-time caused by X-ray-tube failures.",
            "It is also available with networking solutions that link multiple CTX EDS for efficient image distribution and system monitoring and control among operators.",
            "For smaller airports you might be interested in our explosive detection system CTX 5800.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/bio1.pdf",
            "../../../public/pdf/bio2.pdf"
        ],
        Technical_Specification: [
            "Belt speed 0.3m/sec - 0.5m/sec (up to 1,800BPH)",
            "Customized networking solutions",
            "Intuitive user interface",
            "Efficient power consumption",
            "High resolution 3D images from a single X-ray source",
            "Level-1 decision and Level-2 image made before bag exits the machine",
        ],
        images: [
            require("../../assest/products/ctx-dsi.png"),
            require("../../assest/products/ctx-dsi.png"),
            require("../../assest/products/ctx-dsi.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 7,
        title: "DaiSy",
        subTitle: "Detailed-analysis software platform",
        description: "Included in all Smiths Detection HCV systems, DaiSy gives operators access to customizable image treatments and comparison tools, for the rapid interpretation of images and verification of cargo.",
        Overview: [
            "DaiSy is the software platform installed as standard on all our HCV cargo and vehicle inspection solutions.",
            "It provides operators with a variety of customizable image treatments, options and comparison tools, to aid in the timely interpretation of images and the verification of cargo.",
            "It quickly and reliably delivers detailed images for analysis, increasing cargo throughput and protecting borders and entry points against the illegal movement of contraband, alcohol, explosives, arms, dirty bombs and other illegal substances.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/Daisy.pdf",
        ],
        Technical_Specification: [
            "Ergonomic and intuitive GUI (no more than 2-mouse clicks to perform image analysis)",
            "Increased operator efficiency due to a range of customisable tools",
            "A full set of operator roles; check-in, image analysis, recheck, review",
            "Customisable display configurations",
        ],
        images: [
            require("../../assest/products/daisy.png"),
            require("../../assest/products/daisy.png"),
            require("../../assest/products/daisy.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 9,
        title: "HazMatID Elite",
        subTitle: "Hand-held FT-IR chemical identifier",
        description: "HazMatID Elite is a ruggedized hand-held Fourier Transform Infrared (FT-IR) identifier of solid and liquid chemicals.",
        Overview: [
            "The analysis, which takes less than 1 minute, is performed by placing a small amount of the unknown substance on to the diamond ATR sensor and applying pressure with an integrated press for solid samples.",
            "The sample interface also includes an integrated well for the containment of liquid samples.",
            "A second, touch-to-sample, diamond ATR interface is available for the analysis of pooled liquids and surface films, and enables robotic applications.",
            "HazMatID Elite has the widest thermal and solar operational range of any portable chemical identifier. Its revolutionary optical engine also provides high vibration immunity and resistance to mechanical disturbances seen during vehicle or human transportation.",
            "It has intuitive software and a large display screen and keypad controls for ease of operation in protective gear.",
            "PC-based command software provides advanced data-handling capabilities for specialised users.",
            "It is certified to MIL-STD-810G for use in harsh conditions and high temperatures.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/HazMatID Elite1.pdf",
            "../../../public/pdf/HazMatID Elite2.pdf"
        ],
        Technical_Specification: [
            "Integrated pressure device for analysis of solid materials, plus direct touch-to-sample capability",
            "Automated analysis of mixtures with priority alerting for explosives, CWA, TIC and narcotics",
            "Long-range, embedded RF wireless transmission",
            "MIL-STD-810G Rugged and IP67 for decon",
        ],
        images: [
            require("../../assest/products/hazMatlD.png"),
            require("../../assest/products/hazMatlD.png"),
            require("../../assest/products/hazMatlD.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 10,
        title: "HCVG",
        subTitle: "Gantry inspection system",
        description: "The HCVG provides side view images of inspected vehicles, offering material discrimination for easy assessment of cargo and vehicle contents in a single scan.",
        Overview: [
            "The HCVG X-ray system uses a single scan to screen whole trucks (cabin included), containers and vehicles for threats such as explosives, narcotics, weapons of mass destruction (WMDs), contraband, as well as manifest verification, reducing the need for manual inspection.",
            "The system’s high-performance imaging capability provides the operator with detailed radioscopic images of container or vehicle and its contents, with organic and inorganic material discrimination and colourisation based on atomic number, allowing for rapid and reliable results in a single scan.",
            "The HCVG viZual series systems use an accelerator delivering an interlaced energy from 4/6MeV, allowing for steel penetration ranging from 320mm (12.6in) to 400mm (15.7in) while providing a high throughput of up to 23 trucks per hour.",
            "When equipped with the optional iCmore radioactivity capability, the HCVG simultaneously carries out both the X-ray inspection and analysis to detect the presence of radioactive gamma and/or neutron materials within the container or vehicle.",
            "Thanks to the DaiSy graphic-user interface, operators can easily operate their tasks, such as the X-ray image analysis. An option is the use of a remote pool of operators to analyse the X-ray images, permitting the optimization of the team size.",
            "The modular design of the HCVG provides the ability for the system to be relocated, adapting to the customer’s specific needs. The HCVG is designed for ease of operation, requiring a minimal footprint and external infrastructure while still integrating the most demanding international security-screening requirements.",
        ],
        Featured_Highlights: [
            "",
            "",
        ],
        Product_downloads: [
            "",
            ""
        ],
        Technical_Specification: [
            "Inspect loaded trucks, containers and vehicles at ports, airports and border crossings",
            "High throughput of up to 23 trucks per hour",
            "Steel penetration up to 400mm (15.7in) @ 6MeV",
            "Adapted footprint and security perimeter",
            "Provides a high performance imaging capability with organic/inorganic material discrimination and colourization in a single scan",
        ],
        images: [
            require("../../assest/products/HCVG.png"),
            require("../../assest/products/HCVG.png"),
            require("../../assest/products/HCVG.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 11,
        title: "HCVM",
        subTitle: "Mobile high-energy inspection system",
        description: "Available as either a mobile cargo vehicle or a trailer, the HCVM X-ray screening system optimizes security checks at airports, ports and borders.",
        Overview: [
            "The HCVM X-ray screening system is designed for ease of operation and can be driven from location to location and be ready for use in less than 30 minutes.",
            "The HCVM requires a minimal footprint and external infrastructure while still meeting the most demanding international security-screening requirements.",
            "This system uses a range of accelerators from 4MeV to 6MeV and a variety of scanning modes. The combination of these capabilities with high mobility makes this product ideal for applications that require the quick and easy relocation of the screening checkpoint.",
            "When equipped with the optional iCmore™ radioactive-material detection system, the HCVM can carry out simultaneous X-ray inspections and an analysis to detect radioactive gamma and or neutron materials.",
            "The HCVM L version is an all-in-one mobile-screening lorry.  The HCVM T version of the range can be towed by a standard truck.",
        ],
        Featured_Highlights: [
            "",
            "",
        ],
        Product_downloads: [
            "",
            ""
        ],
        Technical_Specification: [
            "Throughput of up to 25 trucks an hour in mobile scanning mode and up to 100 trucks an hour in pass-through mode",
            "Steel penetration up to 320mm (12.6in)",
            "viZual technology for organic/inorganic material discrimination at 6MeV",
            "Up to six scanning modes",
            "Adjustable scanning heights and angles",
        ],
        images: [
            require("../../assest/products/hcvm.png"),
            require("../../assest/products/hcvm.png"),
            require("../../assest/products/hcvm.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 12,
        title: "HCVP",
        subTitle: "Drive through portal",
        description: "An automatic, drive through scanner portal for the inspection of vehicles and containers, guaranteeing constant vehicle flow on site.",
        Overview: [
            "HCVP is a drive-through portal for the inspection of trucks, containers and other vehicles, for explosives, drugs and weapons.",
            "It can also provide manifest verification, reducing the need for manual inspections.",
            "Its accelerators allow for steel penetration from 230mm (9in) to 300mm (11.8in) while providing a high throughput.",
            "The HCVP is also available with options that include viZual technology for the identification of organic/inorganic materials, automatic radioactive material detection, optical character recognition and options for bumper-to-bumper scans for countries where scanning people is allowed.",
        ],
        Featured_Highlights: [
            "",
            "",
        ],
        Product_downloads: [
            "",
            ""
        ],
        Technical_Specification: [
            "Throughput of 100 to 120 vehicles an hour",
            "Small footprint",
            "Quick and reliable search results",
        ],
        images: [
            require("../../assest/products/hcvp.png"),
            require("../../assest/products/hcvp.png"),
            require("../../assest/products/hcvp.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 13,
        title: "HCVS",
        subTitle: "Stationary inspection system",
        description: "A stationary solution providing analysis of both the sides and top of an inspected vehicle, using material discrimination to reduce manual inspection.",
        Overview: [
            "The HCVS X-ray screening systems optimises security checks at ports and border crossings by screening whole trucks (cabin included), containers, and vehicles for threats and illegal contraband. With the ability discriminate to different organic/inorganic materials, the HCVS reduces the need for manual inspection.",
            "HCVS  provides both a top and a side X-ray image of the truck scanned, assisting in detection an exact location of suspicious items. It is capable of penetrating 410mm (16.1in) of steel, and has a high throughput of up to 20 trucks per hour.",
            "The system’s high-performance imaging capability provides the operator with detailed radioscopic images of a container or vehicle and its contents, allowing for rapid and reliable results. With viZual technology configuration, available on the side X-ray view, radioscopic images deliver container or vehicle loads with organic and inorganic material discrimination and colourization based on atomic number.",
            "With the addition of the DaiSy graphic user-interface, operators can easily operate their tasks, as the X-ray image analysis.",
            "When equipped with the optional iCmore automatic radioactive material detection capability, the HCVS simultaneously carries out both the X-ray inspection and an analysis to detect the presence of radioactive gamma and/or neutron materials within the container or vehicle.",
            "The HCVS systems have proven to be an indispensable tool for customs agencies and law enforcement authorities worldwide.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/HCVS.pdf",
        ],
        Technical_Specification: [
            "Inspect loaded trucks, containers and vehicles at ports and border crossings",
            "Dual X-ray image analysis",
            "High throughput of up to 20 trucks per hour",
            "Steel penetration up to 410mm (16.1in) @ 9 MeV",
            "Adapted footprint and security perimeter",
        ],
        images: [
            require("../../assest/products/HCVS.png"),
            require("../../assest/products/HCVS.png"),
            require("../../assest/products/HCVS.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 14,
        title: "HCVT",
        subTitle: "Rail cargo inspection system",
        description: "The HCVT delivers side-view X-ray images of freight wagons or railcars.",
        Overview: [
            "The HCVT side-view X-ray inspection solution analyses rail cars and wagons to reveal dangerous or illegal cargo, such as cigarettes, explosives, narcotics and weapons.",
            "With its powerful 6MeV generator, the HCVT can scan up to 300 containers in 10 minutes. It has the ability to see through 300mm (11.8”) of steel, when a train is travelling at an optimal speed of 15km/hr.",
            "Smiths Detection’s powerful DaiSy software provides operators with access to a variety of customizable image treatments, options and comparison tools to support effective interpretation of images and verification of cargo.",
            "Our CargoVision™ operating platform can also be upgraded with additional options such as automatic container code recognition, for the quick retrieval of associated dataset information.",
            "HCVT can be networked with Customs EDI networks and other HCV systems, to support remote analysis, comparison and knowledge sharing.",
        ],
        Featured_Highlights: [
            "",
            ""
        ],
        Product_downloads: [
            "",
            ""
        ],
        Technical_Specification: [
            "Helps to intercept dangerous goods and contraband transported by rail",
            "Automates processes and links to Customs EDI networks and other HCV systems",
            "Inspects rail cars and wagons travelling at a max. speed of 30 km/h",
            "Ergonomic operator interface, delivering separate images for each wagon",
            "No radioactive source used to emit X-rays",
        ],
        images: [
            require("../../assest/products/HCVT.png"),
            require("../../assest/products/HCVT.png"),
            require("../../assest/products/HCVT.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 15,
        title: "HI-SCAN 100100T",
        subTitle: "Single-view scanning for large objects",
        description: "Ideal for inspecting over-sized baggage and freight, the HI-SCAN 100100T is a state-of-the-art X-ray inspection system for heavy objects up to a size of 100 x 100cm.",
        Overview: [
            "The HI-SCAN 100100T is a state-of-the art X-ray inspection system for scanning objects up to a size of 100 x 100cm.",
            "Designed to meet the needs of airports, customs facilities, transportation operations, carriers and parcel services – in applications where high security and the screening of large objects are required.",
            "The low installation height of the conveyor system facilitates the connection of supplementary feed and/or discharge conveyor systems for heavy freight.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/HI-SCAN 100100T.PDF",
        ],
        Technical_Specification: [
            "Ideal for inspecting over-sized baggage and freight",
            "Low conveyor belt allows for easy handling of heavy goods",
            "Outstanding image quality with HiTraX electronics",
            "Wire detectability AWG 38",
            "HI-MAT Plus technology provides better material distinction",
            "Tunnel size in optimal proportion to entire system",
            "Typical steel penetration 32mm",
        ],
        images: [
            require("../../assest/products/Bio.png"),
            require("../../assest/products/Bio.png"),
            require("../../assest/products/Bio.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 16,
        title: "HI-SCAN 100100T-2is",
        subTitle: "Dual-view scanner for large objects",
        description: "Featuring a low conveyor belt, the dual-view X-ray HI-SCAN 100100T-2is is designed for screening over sized baggage and bulky freight.",
        Overview: [
            "Equipped with two generators arranged at a 90° angle to each other, the HI-SCAN 100100T-2is provides Dual-view screening of objects up to 100 x 100cm (39 x 39in). The dual-view screening mode provides reliable inspection of tightly packed objects in a single process, while shortening inspection times and increasing the effectiveness of the procedure.",
            "With the low installation height of the HI-SCAN 100100T-2is conveyor belt, it is simple to connect supplementary feed and/or discharge conveyor systems for heavy freight.",
            "Following IATA’s recommendations regarding Dangerous Goods, the HI-SCAN 100100T-2is is also available with an option for detecting lithium batteries. Want to know more? Click here to learn about how to you can mitigate the risks.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/HI-SCAN 100100T-2is1.pdf",
            "../../../public/pdf/HI-SCAN 100100T-2is2.PDF"
        ],
        Technical_Specification: [
            "Optional lithium battery detection",
            "Ideal for inspecting oversized baggage and freight",
            "Dual-view shortens inspection times",
            "Low conveyor belt height allows for easy handling",
            "Maximum load of 220kg",
            "HI-MAT Plus technology for better material distinction",
            "Typical penetration: 35mm of steel (view 2), 37mm (view 1)",
        ],
        images: [
            require("../../assest/products/100100t-2is.png"),
            require("../../assest/products/100100t-2is.png"),
            require("../../assest/products/100100t-2is.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 17,
        title: "HI-SCAN 100100V",
        subTitle: "Break-bulk cargo scanner",
        description: "A compact X-ray security screening system, the HI-SCAN 100100V is designed for facilities requiring high-security screening of items up to a size of 100 x 100cm.",
        Overview: [
            "The HI-SCAN 100100V is a compact X-ray security screening system with tunnel dimensions of 100cm by 100cm.",
            "Designed for facilities requiring high security screening of items with a large range of dimensions.",
            "It permits the inspection of bulky as well as small items without any loss of X-ray image quality, and is designed to meet the needs and applications of airports, customs facilities, transportation operations, carriers and parcel services.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/HI-SCAN 100100V.PDF",
        ],
        Technical_Specification: [
            "Universal X-ray system for largest variety of item dimensions",
            "Excellent image presentation thanks to optimal X-ray geometry",
            "Outstanding image quality thanks to HiTraX electronics",
            "Bottom shooter imager generation",
            "Wire detectability AWG 38",
            "New HI-MAT Plus technology for better material discrimination",
        ],
        images: [
            require("../../assest/products/HI-Scan-100100Vwebp.png"),
            require("../../assest/products/HI-Scan-100100Vwebp.png"),
            require("../../assest/products/HI-Scan-100100Vwebp.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 18,
        title: "HI-SCAN 100100V-2is",
        subTitle: "Break-bulk cargo screening",
        description: "Featuring a high-speed conveyor belt, the HI-SCAN 100100V-2is provides high-quality images of screened objects of different sized baggage and parcels.",
        Overview: [
            "The HI-SCAN 100100V-2is is equipped with two 160kV generators in 90° opposition, providing a horizontal and vertical view of the screened object (dual-view). This screening method facilitates reliable inspections of tightly packed objects in one process, shortening inspection times.",
            "Designed to meet the requirements of airports, customs facilities, logistics companies and parcel services, its compact system design provides the highest performance with the smallest footprint and offers two different conveyor speeds, 0.2 m/s and 0.5 m/s.",
            "The high-speed HI-SCAN 100100V-2is model delivers high throughput break bulk cargo screening for fast turnaround, high volume air cargo applications.",
            "The HI-SCAN 100100V-2is is available with an option for automatically detecting lithium batteries as well as weapons. Want to know more? Click here to learn how you can mitigate the risks of dangerous goods.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/HI-SCAN 100100V-2is1.PDF",
            "../../../public/pdf/HI-SCAN 100100V-2is2.pdf",
            "../../../public/pdf/HI-SCAN 100100V-2is3.pdf"
        ],
        Technical_Specification: [
            " Optional lithium battery detection",
            " Dual-view shortens inspection times",
            "Smallest footprint in its range",
            "Conveyor height of 60cm",
            "Conveyor load of 200/220kg",
            "Typical steel penetration of 37mm in both views",
            "ECAC STP compliant",
        ],
        images: [
            require("../../assest/products/HI-Scan-100100V-2is.png"),
            require("../../assest/products/HI-Scan-100100V-2is.png"),
            require("../../assest/products/HI-Scan-100100V-2is.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 19,
        title: "HI-SCAN 10080 EDX-2is",
        subTitle: "Automatic explosives detection system",
        description: "Using a dual view X-ray system, the HI-SCAN 10080 EDX-2is improved the evaluation process allows operators to ‘look behind’ objects while automatically screening baggage for explosives.",
        Overview: [
            "Within the proven multi-level method of checked-baggage screening, all baggage is screened automatically for explosives by a high-speed Level-1 system, the EDX-2is. Bags cleared by EDX-2is continue to their determined destination while rejected bags are automatically diverted to a second security level for further inspection, significantly.",
            "With a tunnel opening of 1070mm x 810mm, the EDX-2is allows the screening of oversized objects up to 3.8m, avoiding the need for alternative screening.",
            "With its high detection capability, combined with a low false-alarm rate, the system can screen up to 1,800 bags per hour.",
            "Automatic detection of lithium batteries (iCMORE Lithium Batteries) is offered as an option on this scanner.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/HI-SCAN 10080 EDX-2is.PDF",
        ],
        Technical_Specification: [
            "Dual-view system",
            "Approved according to EU regulation 1448/2006 - Standard 2",
            "Highest rate of detection in its class",
            "Tunnel opening 100 x 80cm suitable for oversized luggage",
            "Screening rate of up to 1,800 luggage items per hour",
            "STAC and DfT certified"
        ],
        images: [
            require("../../assest/products/HI-Scan-100100V-2is-Edx.png"),
            require("../../assest/products/HI-Scan-100100V-2is-Edx.png"),
            require("../../assest/products/HI-Scan-100100V-2is-Edx.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 20,
        title: "HI-SCAN 10080 XCT",
        subTitle: "Advanced CT explosives detection system",
        description: "Complying with current TSA and ECAC regulations, the HI-SCAN 10080 XCT is a next generation high-speed, explosives detection system for hold baggage and air cargo.",
        Overview: [
            "The HI-SCAN 10080 XCT is a next generation high-speed, explosives detection system (EDS) for hold baggage and air cargo. It features a dual-view dual-energy X-ray line scanner with full 3D volumetric computed tomography (CT) imaging and reconstruction.",
            "The HI-SCAN 10080 XCT has a belt speed of 0.5m/sec (98.5ft/min) and a large 107 x 81 cm (42.1 x 31.9in) rectangular tunnel. It is designed to be integrated into fully automated and networked baggage and material handling systems. This combination offers airports a throughput of up to 1,800 bags/hour and air cargo handlers the capability to screen 2,500 parcels/hour.",
            "The HI-SCAN 10080 XCT has qualified under the U.S. Safety Act, which sets limited liability for claims related to terrorism. It is TSA certified for high-speed checked baggage screening and EU/ ECAC EDS Standard 3.0 and 3.1 as well as TSA ACSTL approved.",
            "Automatic detection of dangerous goods such as lithium batteries (iCMORE Lithium Batteries) as well as flammable solids and liquids, compressed and liquefied gases (iCMORE Dangerous Goods) is offered as an option on this scanner.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/10080.pdf",
            "../../../public/pdf/100800.pdf"
        ],
        Technical_Specification: [
            "TSA certified for high-speed checked baggage screening",
            "TSA ACSTL approved",
            "EU/ ECAC EDS Standard 3.0 and 3.1 approved",
            "Certified by STAC, DfT",
            "Belt speed of 0.5m/sec (98.5ft/min)",
            "Throughput of up to 1,800 bags per hour",
            "Tunnel capable of screening objects 100 x 80cm",
        ],
        images: [
            require("../../assest/products/XCT.png"),
            require("../../assest/products/XCT.png"),
            require("../../assest/products/XCT.png"),
        ],
        category: "X-RAY SCREENING",
    },
    // here
    {
        id: 21,
        title: "HI-SCAN 145180-2is",
        subTitle: "Large objects dual view scanner",
        description: "Universal X-ray system for palletised items, ideal for use in warehouses. Dual view for shorter inspection times.",
        Overview: [
            "The HI-SCAN 145180-2is has two 160kV generators in a 90° opposition providing a horizontal and vertical view of the object (dual-view) for shortened inspection time and high-quality images.",
            "The tunnel dimensions of 1,450mm (57in) width and 1,800mm (71in) height is suitable for the inspection of air cargo transported on standard Euro, British or US pallets.",
            "For large-scale cargo screening, check the Advanced HI-SCAN 180180 2is pro.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/HI-SCAN 145180-2is-111.PDF",
        ],
        Technical_Specification: [
            "Universal X-ray system for palletised items",
            "Dual view for shorter inspection times",
            "Small-pitch roller conveyor, capacity 1,000kg/m – or up to 5,000 kg when evenly-distributed",
            "Meets TSA requirements for max. skid size of 1.2 x 1.2 x 1.65 m",
            "Typical penetration: 37mm steel",
            "HI-MAT Plus technology for advanced material discrimination",
        ],
        images: [
            require("../../assest/products/HI-Scan-2is.png"),
            require("../../assest/products/HI-Scan-2is.png"),
            require("../../assest/products/HI-Scan-2is.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 22,
        title: "HI-SCAN 145180-2is pro",
        subTitle: "Fast, effective air cargo screening",
        description: "The innovative air cargo screening system HI-SCAN 145180-2is pro meets the demand for fast turnaround, high levels of security and an attractive total cost of ownership (TCO).",
        Overview: [
            "The innovative air cargo screening system HI-SCAN 145180-2is pro meets the demand for fast turnaround, high levels of security and an attractive total cost of ownership (TCO).",
            "This state-of-the-art scanner is equipped with two 200 kV X-ray generators, one of which is uniquely positioned under the conveyor belt, to deliver excellent penetration, superior image quality, more efficiency, and easy access for maintenance and service.",
            "In-depth research and extensive testing were undertaken to establish the optimum generator design for a long lifespan. This is just one contributing factor in the impressively low TCO – others include cost of spare parts; easy service access; and, as space is such an expensive commodity, the particularly compact footprint.",
            "The generators are digitally controlled enabling advanced analysis and diagnosis capabilities and there is also an internal memory, storing important lifecycle data. Equipped with the latest HiTraX 3 CS electronics, the HI-SCAN 145180-2is pro offers state-of-the-art technology for advanced software features and image processing.",
            "A robust conveyor features tightly packed rollers to support smooth passage through the inspection tunnel for pallets and other bulky and heavy objects. The large tunnel handles objects up to 145cm x180cm. Integration into material handling systems is straightforward.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/HI-SCAN 145180-2is pro-22.pdf",
            "../../../public/pdf/HI-SCAN 145180-2is pro-33.pdf"
        ],
        Technical_Specification: [
            "Increases security and efficiency",
            "Supports fast turn-around",
            "Excellent penetration and superior image quality",
            "Easy maintenance access",
            "Compact footprint",
        ],
        images: [
            require("../../assest/products/pro.png"),
            require("../../assest/products/pro.png"),
            require("../../assest/products/pro.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 23,
        title: "HI-SCAN 180180-2is pro",
        subTitle: "Advanced dual view X-ray cargo scanner",
        description: "An X-ray scanner designed for the inspection of large scale consolidated and palletised goods, ideal for cargo inspection.",
        Overview: [
            "An X-ray scanner designed for the inspection of large-scale consolidated and palletised goods.",
            "Tunnel dimensions of 180 x 180cm means the HI-SCAN 180180-2is pro is capable of screening LD3 containers.",
            "Based on a dual-view concept, the system provides two sharp independent views of the screened goods and, as a result, it delivers more accurate and reliable image evaluation.",
            "Two powerful 300 kV X-ray generators are capable of penetrating even large and dense objects, reducing reinspection times (as consolidated cargo does not usually need to be broken down into smaller items), ensuring high throughput and a fast, efficient inspection process.",
            "The small-pitch roller conveyors ensure a smooth passage for the scanned goods and supports a total load of up to 5,000kg/m (evenly distributed).",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/HI-SCAN 180180-2is pro-555.pdf",
        ],
        Technical_Specification: [
            "Dual view shortens inspection times",
            "Beam geometry facilitates the detection of potential threats",
            "Rugged roller conveyors accepts loads of up to 5 tonnes when evenly distributed ",
            "High-penetration capabilities to avoid disassembly into individual packages",
        ],
        images: [
            require("../../assest/products/si-pro-18180.png"),
            require("../../assest/products/si-pro-18180.png"),
            require("../../assest/products/si-pro-18180.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 24,
        title: "HI-SCAN 5030C",
        subTitle: "Compact & mobile X-ray inspection system",
        description: "A transportable and lightweight X-ray scanner, ideal for screening in mail rooms or building entrances, including: courthouses, schools, event venues, stadiums or correctional facilities.",
        Overview: [
            "The HI-SCAN 5030C X-ray scanner is an ideal solution for screening mail and personal belongings in commercial and public buildings due to its size, mobility and durability.",
            "The HI-SCAN 5030C is specifically designed to enhance security screening operations from courthouses to corporate campuses. Its compact size and lightweight construction enable it to fit seamlessly into checkpoints and mailrooms. Vibration absorbers make it possible to transport HI-SCAN 5030C between various locations without requiring re-calibration, providing maximum flexibility.",
            "Featuring proven HiTrax 3 electronics and software platform to enhance and expedite security screening operations. It produces reliable and easy-to-interpret scanned images enabling screening personnel to make threat and contraband assessment of parcels to personal items quickly and confidently.",
            "The HI-SCAN 5030C is available as a stationary desktop scanner or as a mobile version on wheels featuring vibration shock absorbers and a storage compartment.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/HI-SCAN 5030C7777.pdf",
        ],
        Technical_Specification: [
            "Lightweight and transportable",
            "Advanced HiTraX 3 electronic platform",
            "Easy-to-use",
            "Excellent image resolution",
            "On-board storage",
            "Customizable add-on options",
        ],
        images: [
            require("../../assest/products/hi-5030C.png"),
            require("../../assest/products/hi-5030C.png"),
            require("../../assest/products/hi-5030C.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 25,
        title: "HI-SCAN 6040 aTiX",
        subTitle: "X-ray inspection system",
        description: "An advanced multi-view X-ray system for the automatic detection of solid and liquid explosives in cabin baggage.",
        Overview: [
            "HI-SCAN 6040aTiX is an advanced multi-view X-ray system for the automatic detection of solid and liquid explosives in cabin baggage.",
            "The detection performance of HI-SCAN 6040aTiX is far superior to other automatic systems, including lower false-alarm rates. Using advanced computer technologies, complex analyses can be carried out in real-time, maximising baggage throughput without hampering normal checkpoint procedures.",
            "The two detailed views support the quick and reliable evaluation process.",
            "It was the industry’s first system to achieve ECAC LEDS Type C Standard 2 certification for screening of liquids, and has since gained LEDS Type C Standard 3 as well as EDS CB C1 and C2 for cabin baggage.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/HI-SCAN 6040 aTiX-88.pdf",
            "../../../public/pdf/HI-SCAN 6040 aTiX--888.PDF",
            "../../../public/pdf/HI-SCAN 6040 aTiX-8.pdf"
        ],
        Technical_Specification: [
            "Zeff and density analysis using multiple independent views",
            "Proven, ergonomic operating concept",
            "Multi-view technology provides high-resolution, detailed images",
            "X-ray generator with optimized spectrum",
            "High-resolution XADA sensor technology",
            "ECAC certified",
        ],
        images: [
            require("../../assest/products/hi-6040atix.png"),
            require("../../assest/products/hi-6040atix.png"),
            require("../../assest/products/hi-6040atix.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 26,
        title: "HI-SCAN 6040 CTiX",
        subTitle: "Advanced screening using CT technology",
        description: "Meeting the highest available regulatory standards, the HI SCAN 6040 CTiX is available with ECAC EDS CB C3 approval and TSA APSS 6.2 Level 1 certification.",
        Overview: [
            "The HI-SCAN 6040 CTiX and the updated Model S offer advanced screening of carry-on baggage using Computed Tomography (CT) and automatic explosives detection algorithms. In addition to both models achieving ECAC EDS CB C3, HI-SCAN 6040 CTiX is also STAC, ENAC and IPMO approved; and has TSA APSS v6.2 Level 1 certification. These are also currently underway for HI-SCAN 6040 CTiX Model S. ECAC EDS CB C3 approved systems eliminate the need to remove electronic devices, liquids and gels from hand luggage– expediting screening and making the whole process less stressful for passengers.",
            "The gantry rotates at a constant speed as baggage is carried through it on the conveyor belt. Hundreds of images are taken to create high quality 3D images in real-time, allowing for more accurate judgments on the bag contents. The user interface received the 2020 Red Dot Award for fast and intuitive 3D image evaluation.",
            "Due to the considerable reduction in width by 20cm and weight by 350kg, the HI-SCAN 6040 CTiX Model S is easily integrated into existing Smiths Detection and third-party checkpoints as well as checkpoints with size and layout limitations.",
            "Both, HI-SCAN 6040 CTiX and the Model S can be integrated with the automated object recognition software iCMORE, which can detect prohibited items such as weapons. Click here to learn more.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/HI-SCAN 6040 CTiX-7.pdf",
            "../../../public/pdf/HI-SCAN 6040 CTiX-77.pdf",
            "../../../public/pdf/HI-SCAN 6040 CTiX-777.pdf",
            "../../../public/pdf/HI-SCAN 6040 CTiX-7777.pdf",
            "../../../public/pdf/HI-SCAN 6040 CTiX-77777.pdf",
        ],
        Technical_Specification: [
            "ECAC and STAC EDS CB C3 approved",
            "TSA APSS 6.2 Level 1 certified",
            "Qualified on the TSA’s Checkpoint Property Screening System (CPSS) Qualified Products List (QPL) for base, mid and full-size configurations",
            "High throughput levels through highest belt speed in its class (0.2m/s)",
            "5-20% lower power consumption compared to alternative systems",
            "Low noise level (60 dB), zero vibration",
            "High detection rate and low false-alarm rate thanks to MAT technology",
            "Easily integrated into existing checkpoints, supporting open architecture solutions",
            "Optimized weight and footprint of the Model S",
        ],
        images: [
            require("../../assest/products/ctx.png"),
            require("../../assest/products/ctx.png"),
            require("../../assest/products/ctx.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 27,
        title: "HI-SCAN 6040 DV",
        subTitle: "High resolution, dual-view X-ray screening",
        description: "An advanced dual-view X-ray inspection system for automatic explosives and liquids detection at Urban Security and Aviation checkpoints.",
        Overview: [
            "HI-SCAN 6040 DV is an advanced dual-view X-ray inspection system for automatic detection of explosives and liquids. The dual-view increases operational efﬁciency by eliminating the need to re-position or re-scan objects.",
            "Offering superior image quality, smart display functions and easy integration into tray handling and management systems, it is the ideal solution for efﬁcient screening in high threat applications such as airports, government facilities, embassies, or banks.",
            "EDS CB C1 and LEDS algorithms – in combination with the optional iCMORE weapons capability for automatic detection of pistols, revolvers, gun parts, ﬂick, and ﬁxed blade knives – elevate security, efﬁciency, and automation, without requiring additional hardware. Click here to learn more.",
            "The HI-SCAN 6040 DV also provides high levels of protection against cyber threats by applying industry best practices such as operating system hardening, a host-based ﬁrewall, TripWire integrity monitoring and powerful user management with highly conﬁgurable password policies.",
            "HI-SCAN 6040 DV is compatible with the Checkpoint.Evoplus screening and management platform which supports actionable operational data insights and health status monitoring plus centralised screening.",
            "Straightforward integration into Smiths Detection or third-party tray handling lanes makes it an ideal choice for high-throughput applications.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/HI-SCAN 6040 DV-33.pdf",
            "../../../public/pdf/HI-SCAN 6040 DV-333.pdf"
        ],
        Technical_Specification: [
            "Specification Title 1",
            "ECAC EDS CB C1 & LEDS Standard 2 Type C approved",
            "Smart display functions",
            "Easy integration into tray handling systems",
            "High level of cyber security",
            "Compact footprint – weight 820kg/1808 lbs",
        ],
        images: [
            require("../../assest/products/hi-6040Dv.png"),
            require("../../assest/products/hi-6040Dv.png"),
            require("../../assest/products/hi-6040Dv.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 28,
        title: "HI-SCAN 6040-2is HR",
        subTitle: "Dual view X-ray inspection for carry-on luggage",
        description: "An advanced dual-view X-ray inspection system for the automatic detection of solid and liquid explosives in checked baggage.",
        Overview: [
            "An advanced dual-view X-ray inspection system for the automatic detection of solid and liquid explosives in personal belongings and cabin baggage. The HI-SCAN 6040-2is HR has an ergonomic design that makes it easy for the operator to identify threats on screen.",
            "Approved for ECAC EDS CB C1 means that it can be used for the screening of checked cabin baggage. It also meets EU legislation for LEDS Standard 2 and 3 Type C – an extended approval as a Liquid Explosive Detection System (LEDS).",
            "Using proven X-ray technology, it generates reliable, high-quality dual-view images, and it is also available in standard resolution (not approved for aviation cabin baggage).",
            "Though primarily used for airport screening, its compact footprint makes it suitable for locations with space constraints such as hotels and government buildings.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/HI-SCAN 6040-2is HR.1.pdf",
            "../../../public/pdf/HI-SCAN 6040-2is HR.2.pdf",
            "../../../public/pdf/HI-SCAN 6040-2is HR.3.pdf"
        ],
        Technical_Specification: [
            "Compact footprint",
            "Steel penetration of 35mm",
            "Flexible design for easier installation",
            "Dual view technology",
            "Operator-friendly interface",
        ],
        images: [
            require("../../assest/products/hi-6040-2is-hr.png"),
            require("../../assest/products/hi-6040-2is-hr.png"),
            require("../../assest/products/hi-6040-2is-hr.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 29,
        title: "HI-SCAN 6040C",
        subTitle: "Airport quality screening for buildings & events",
        description: "Designed to screen bags and parcels for prohibited and dangerous items, the HI-SCAN 6040C is ideal for screening at building entrances.",
        Overview: [
            "HI-SCAN 6040C is designed to screen bags, parcels and other objects for prohibited and dangerous items at building entrances.",
            "Generating highly detailed, easily interpreted X-ray images, with a background-contrast function that improves the recognizability of objects on the screen.",
            "Intelligent image-display functions support the operator in the rapid and reliable distinction of organic and inorganic threats.",
            "It can also provide real-time diagnostics on its operating status, ensuring easy handling and troubleshooting.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/HI-SCAN 6040C.pdf",
        ],
        Technical_Specification: [
            "Designed for use at building entrances",
            "High reliability",
            "Best-in-class image quality",
            "Easy to operate",
            "Upgradable electronics and software",
            "Uses next-generation HiTrax Software & Hardware",
            "Cost-optimised",
        ],
        images: [
            require("../../assest/products/hi-6040C.png"),
            require("../../assest/products/hi-6040C.png"),
            require("../../assest/products/hi-6040C.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 30,
        title: "HI-SCAN 6040i",
        subTitle: "Ideal for screening at checkpoints and building entrances",
        description: "This X-ray unit is ideal for screening personal belongings and cabin baggage at aviation checkpoints and building entrances.",
        Overview: [
            "This X-ray unit is for screening personal belongings and cabin baggage at airport checkpoints and critical infrastructure.",
            "It is easy to operate and increases throughput at security checkpoints. Its material discrimination technology ensures reliable image analysis.",
            "The HI-SCAN 6040i is the most successful X-ray inspection system in its class, with more than 12,000 installations worldwide.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/6040i.PDF",
        ],
        Technical_Specification: [
            "One of the most sold systems world-wide",
            "Easy to operate",
            "Powerful 140kV generator for steel penetration up to 31mm ",
            "Integrates with iLane checkpoint designs",
        ],
        images: [
            require("../../assest/products/hi-6040i.png"),
            require("../../assest/products/hi-6040i.png"),
            require("../../assest/products/hi-6040i.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 31,
        title: "HI-SCAN 6046si",
        subTitle: "X-ray inspection system",
        description: "Utilizing high resolution XADA detector modules, the HI-SCAN 6046si produces extremely detailed X-ray images of screened baggage and parcels.",
        Overview: [
            "HI-SCAN 6046si produces extremely detailed X-ray images thanks to high-resolution XADA detector modules. The considerably enhanced image resolution of the scanned objects is a decisive advantage for security evaluation.",
            "The HI-SCAN 6046si integrates with iLane.pro for individualized checkpoint designs and improved throughput.",
            "It also has a higher tunnel opening and can therefore scan objects that exceed standard dimensions for hand luggage in the aviation industry.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/6046si-1.pdf",
            "../../../public/pdf/6046si-2.pdf",
            "../../../public/pdf/6046si-3.PDF"
        ],
        Technical_Specification: [
            "Ideal for small or medium airports and building entrances",
            "Produces extremely detailed X-ray images with XADA detector modules",
            "160kV generator can penetrate steel up to 37mm thick",
            "Easily integrates with iLane checkpoint designs",
        ],
        images: [
            require("../../assest/products/hi-6046i.png"),
            require("../../assest/products/hi-6046i.png"),
            require("../../assest/products/hi-6046i.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 32,
        title: "HI-SCAN 7555 aTiX",
        subTitle: "Versatile automatic explosives detection system",
        description: "With automatic detection of solid and liquid explosives, the HI-SCAN 7555aTiX is ideal for the screening of hand luggage, hold baggage, parcels and small air cargo.",
        Overview: [
            "Due to it’s size and tunnel opening the HI-SCAN 7555aTiX is very versatile and suitable for application in the fields of cabin baggage, hold baggage or parcel screening.",
            "The HI-SCAN 7555aTiX provides automatic detection of solid and liquid explosives during the screening of checked or hold baggage, parcels or small items of air cargo.",
            "An aperture opening of 75 x 55cm, combined with a maximum duty conveyor load of 250kg, provides the flexibility to screen a wide range of items, including heavier freight items.",
            "Performing with high detection and very low false alarm rates, the HI-SCAN 7555aTiX can be fully integrated into network environments for system management, facilitating archiving, image distribution and TIP functionalities.",
            "In addition to achieving ECAC EDS Standard 2 for hold-baggage screening, the HI-SCAN 7555aTiX has achieved certification for ECAC EDS cabin baggage C1 and C2. It also meets the EU legislation for LEDS Standard 2 and 3 Type C.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/aTiX.PDF",
        ],
        Technical_Specification: [
            "Automatic detection of explosives in parcels, carry-on or checked baggage",
            "Automatic detection of LAGs in accordance with ECAC Doc. 30, part II",
            "Duty conveyor load max of 250kg allows screening of heavier freight items",
            "Analysis of Zeff and density using independent views",
            "Two high-resolution detailed views (Dual-view)",
            "High-resolution XADA sensor technology",
        ],
        images: [
            require("../../assest/products/hi-7555ATix.png"),
            require("../../assest/products/hi-7555ATix.png"),
            require("../../assest/products/hi-7555ATix.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 33,
        title: "HI-SCAN 7555 DV",
        subTitle: "High resolution, dual-view X-ray screening",
        description: "An advanced dual view X-ray screening system for automatic explosives detection at Airports, Urban Security and Ports & Borders Checkpoints.",
        Overview: [
            "The HI-SCAN 7555i provides high-quality image analysis for the screening of checked baggage and packages.",
            "A compact X-ray inspection system with a tunnel opening of 755 (w) x 555 (h) [mm]. This tunnel size suits the unit to screening checked luggage and packages as well as briefcases, handbags and other small items.",
            "The HI-SCAN 7555i offers operators optimum support in making decisions and reduces inspection times considerably with its online image analysis methods, and a new ergonomic man-machine interface.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/7555i.PDF",
        ],
        Technical_Specification: [
            "High-speed digital signal transmission",
            "HI-MAT Plus advanced material classification",
            "High image resolution",
            "24 Bit real time image processing",
            "Ergonomic user interface",
            "Free programmable priority keys",
        ],
        images: [
            require("../../assest/products/Hi-7555Dv.png"),
            require("../../assest/products/Hi-7555Dv.png"),
            require("../../assest/products/Hi-7555Dv.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 34,
        title: "HI-SCAN 7555i",
        subTitle: "Compact X-ray inspection system",
        description: "The HI-SCAN 7555i provides high quality image analysis for the screening of checked baggage and packages.",
        Overview: [
            "Using an advanced multi-energy X-ray generator, the HI-SCAN 7555si produces extremely detailed X-ray images with more than double the number of pixels compared to other systems. The enhanced image resolution of the scanned objects represents a decisive advantage for image evaluation.",
            "The HI-SCAN 7555si features a large tunnel opening and can therefore scan objects that exceed the aviation industry’s standard dimensions for hand baggage.",
            "Can be integrated in networks for system management, archiving, image distribution and TIP functionalities.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/7555si.PDF",
        ],
        Technical_Specification: [
            "X-ray generator includes optimised spectrum",
            "High-resolution CRISP sensor technology",
            "HiTraX II system platform with extended scope of services",
            "High-resolution image display",
            "Up to 37mm steel penetration",
            "Wire detectability up to AWG 41",
        ],
        images: [
            require("../../assest/products/hi-7555i.png"),
            require("../../assest/products/hi-7555i.png"),
            require("../../assest/products/hi-7555i.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 35,
        title: "HI-SCAN 7555si",
        subTitle: "Advanced X-ray inspection",
        description: "With large tunnel dimensions and an advanced multi energy X-ray generator, the HI-SCAN 7555si provides high quality image analysis of baggage and parcels.",
        Overview: [
            "By automatically detecting a variety of threats, controlled and prohibited items without impacting the speed of the overall image evaluation, iCMORE improves operational efficiency. It has proven to be effectively integrated into normal workflows without impacting the flow of people, bags and cargo. iCMORE supports more efficient resource planning, for customs officers, security operators and other controlling authorities and reduces operational expenditure.",
            "The additional detection capabilities provide invaluable support to all image analysts, reducing the burden in decision making, and are particularly helpful for less experienced operators. It is intuitive and easy to use, thus little or no training is required. The increase in operational efficiency also leads to a faster, more streamlined experience of passengers and visitors that have to pass the checkpoint.",
            "Delivering improved levels of both safety and security, it is designed to identify potential threats and help combat the movement of unsafe, undeclared or illegal items. iCMORE Prohibited Items delivers a high probability of detection and low false alarm rate. As AI algorithms do not tire and are impartial, they reduce the risk of human errors.",
        ],
        Featured_Highlights: [
            "", ""
        ],
        Product_downloads: [
            "", ""
        ],
        Technical_Specification: [
            "Operational Efficiency",
            "User Experience",
            "Increased Security",
        ],
        images: [
            require("../../assest/products/hi-7555is.png"),
            require("../../assest/products/hi-7555is.png"),
            require("../../assest/products/hi-7555is.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 37,
        title: "iLane A20",
        subTitle: "The next generation Automatic Tray Return System",
        description: "iLane A20 was designed to increase throughput, reduce queues and improve operational efficiency at aviation passenger checkpoints. This intelligent lane has evolved from our extensive experience in integrated checkpoints and meets the most demanding customer requirements.",
        Overview: [
            "iLane A20 was designed to increase throughput, reduce queues and improve operational efficiency at aviation passenger checkpoints. This intelligent lane has evolved from our extensive experience in integrated checkpoints and meets the most demanding customer requirements. The latest functionality, including system performance data and health monitoring, result in a highly reliable and available system. iLane A20 is easy to install, maintain and service – once the required modules are connected, configuration is automatic.",
        ],
        Featured_Highlights: [
            "", ""
        ],
        Product_downloads: [
            "", ""
        ],
        Technical_Specification: [
            "Increased throughput & operational efficiency",
            "Modular, flexible and customisable design",
            "Plug & Play installation",
            "Integration with Checkpoint Management System",
            "Advanced system performance and health monitoring"
        ],
        images: [
            require("../../assest/products/iline-a20.png"),
            require("../../assest/products/iline-a20.png"),
            require("../../assest/products/iline-a20.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 38,
        title: "iLane.evo",
        subTitle: "Smart automatic tray return system",
        description: "Delivering a steady flow of trays, the automatic tray return system iLane.evo plays a critical role in streamlining the screening process and delivering the subsequent benefits of increased throughput; optimized operational costs; and an improved passenger experience.",
        Overview: [
            "An effective, automatic tray return system is a primary checkpoint component. By delivering a steady flow of trays, it plays a critical role in streamlining the screening process and delivering the subsequent benefits of increased throughput; optimized operational costs; and an improved passenger experience.",
            "Innovation in lane design and function can remove bottlenecks and keep the process moving. The iLane.evo from Smiths Detection features the latest developments and addresses the key issues of passenger divestment; tray loading and return; re-routing suspicious items; and ‘reclaim’ space at the end of the checkpoint.",
            "The automated screening lane delivers improved productivity, higher throughput and reduced operational costs which represent the measures and benefits of optimum operational efficiency.",
            "iLane.evo is modular in design, offering the flexibility to develop a variety of configurations to meet different requirements and can expand to handle increasing passenger traffic. It is future-proof with the capability to incorporate new inspection systems and functions. In order to ensure the best possible overall results, iLane.evo seamlessly integrates into the other checkpoint components.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/evo.pdf",
        ],
        Technical_Specification: [
            "Modular design",
            "Motorised automatic tray return system",
            "Various parallel divest options",
            "Automatic bag diverter",
            "Defined interfaces for remote screening integration",
        ],
        images: [
            require("../../assest/products/iline-evo.png"),
            require("../../assest/products/iline-evo.png"),
            require("../../assest/products/iline-evo.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 39,
        title: "iLane.pro",
        subTitle: "Modular checkpoint",
        description: "A flexible, modular checkpoint tray handling system tailored to airports of any size, iLane.pro is designed to adapt to ever-changing security and regulatory requirements.",
        Overview: [
            "iLane.pro is a flexible, modular checkpoint designed to meet constantly evolving requirements.",
            "Its wide variety of modular components – many of which can be upgraded – allows individually designed checkpoints that can be intelligently integrated into existing airport environments and infrastructure.",
            "iLane.pro facilitates fast, passenger-friendly and cost-efficient processes with the highest levels of security.",
            "The iLane.pro concept can be combined with the wide range of Smiths Detection’s advanced checkpoint X-ray machines and is an integral element of Checkpoint.Evo.",
            "Design features",
            "Checkpoint designs to facilitate enhanced operations such as:",
            "Remote screening",
            "Image distribution",
            "Directed search / Recheck",
            "Checkpoint management",
            "Innovative modular design allows system to be adapted to available space",
            "Automatic bag spacing (variable 100 – 500mm)",
            "Automatic bag-reject diverter",
            "Motorized conveyor option with variable speed",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/pro-1.PDF",
            "../../../public/pdf/pro-2.pdf"
        ],
        Technical_Specification: [
            "Huge variety of modules",
            "Modular passive and motorized conveyors",
            "Gravity-driven manual tray-return system",
            "Ready for remote-screening integration",
            "Ideal for compact checkpoints or low- to medium-throughput checkpoints",
        ],
        images: [
            require("../../assest/products/iline-pr.png"),
            require("../../assest/products/iline-pr.png"),
            require("../../assest/products/iline-pr.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 40,
        title: "incent.control",
        subTitle: "Web-based system-management platform",
        description: "Brings mobility, flexibility, cost reduction and IT security to those, responsible for managing security checkpoints, explosive detections systems or any other Smiths Detection sensor product.",
        Overview: [
            "A web-based management platform for CXS/EDS networks, incent.control provides mobility, flexibility, cost reduction and IT security to those responsible for managing security checkpoints, explosives detection systems or any other networked Smiths Detection sensor product.",
            "Can be used for checkpoints, hold-baggage screening systems, X-ray units, trace products and other sensors.",
            "Using incent.control, the user’s web browser is connected with the Smiths Detection server.  The application being delivered by the server, there is no need to install any software onto individual devices.",
            "In existing MatriX Server or HMS systems, the activation of incent.control requires only a software update of the server.",
            "incent.control is complementary to existing HMC workstations.",
        ],
        Featured_Highlights: [
            "", ""
        ],
        Product_downloads: [
            "", ""
        ],
        Technical_Specification: [
            "Provides system status and performance information",
            "Accessible from any computer with web browser, including tablets",
            "Web-based, means no specific software installation on client required on specific devices",
            "Intuitive user interface",
            "All communications are encrypted, meeting current IT security standards",
        ],
        images: [
            require("../../assest/products/incent-control.png"),
            require("../../assest/products/incent-control.png"),
            require("../../assest/products/incent-control.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 41,
        title: "incent.view",
        subTitle: "3D & 2D analyst and recheck workstation",
        description: "incent.view workstations allow the review and analysis of X-ray images from all Smiths Detection HiTraX based X-ray systems.",
        Overview: [
            "Incent.view 2D/3D capable workstation allows for high-quality on-screen image resolution combined with ergonomic and intuitive operation.",
            "Incent.view’s image-evaluation features include infinite zoom, single-alarm processing, colour intensity and grey-level absorption, and the full set of HI-TraX-based X-ray units.",
            "Marking and annotation of suspicious areas, workflow integration as Analyst (remote screening) and Recheck, and configuration as either a 2D or 3D workstation are available.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/view.PDF",
        ],
        Technical_Specification: [
            "Excellent image quality for efficient and reliable on-screen resolution",
            "Fast and easy image analysis through various image-manipulation functions",
            "Intuitive operation",
            "Touchscreen-capable",
            "Compatible with Checkpoint.evoplus, HI-SCAN 10080 XCT, and all other HI-SCAN units.",
        ],
        images: [
            require("../../assest/products/incent-view.png"),
            require("../../assest/products/incent-view.png"),
            require("../../assest/products/incent-view.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 42,
        title: "IONSCAN 600",
        subTitle: "Portable explosives and narcotics trace detector",
        description: "IONSCAN 600 is a highly sensitive, non-radioactive, lightweight, portable desktop system that detects and identifies trace amounts of explosives and narcotics.",
        Overview: [
            "The IONSCAN 600 is a highly sensitive trace detector, in a lightweight, portable desktop configuration.  It can be used to accurately detect and identify a wide range of military, commercial and homemade explosives threats and common illegal/controlled narcotics, including the highly potent synthetic fentanyl opioids that are rapidly spreading across the world.",
            "The compact, lightweight IONSCAN 600 is easily portable; it includes hot-swappable batteries for full operation even when mains power is not available.  The unit can be relocated while fully operational (without any downtime) enabling it to be used in a much broader range of screening environments.",
            "It features a proprietary non-radioactive ion mobility spectrometry (IMS) source, which means licensing from national nuclear regulatory agencies is not required.  This allows the detector to be used without time-consuming radiation testing and administration, and enables easy licence-free transport from location to location.",
            "The IONSCAN 600 is unique in working with cost-effective, single-use swabs suitable for both manual and wand sampling.  Designed for efficient trace-particle pick-up, these proprietary swabs reduce contamination risk and offer the most hygienic method for sampling a person’s hands.",
        ],
        Featured_Highlights: [
            "", ""
        ],
        Product_downloads: [
            "", ""
        ],
        Technical_Specification: [
            "Detection and identification in less than 8 seconds",
            "Detects and identifies explosives and narcotics",
            "Non-radioactive IMS source",
            "Small, lightweight and portable",
            "Optional integrated printer",
            "Single use, disposable sampling swabs",
            "Approved/certified by ECAC and CAAC",
        ],
        images: [
            require("../../assest/products/i0nscan-600.png"),
            require("../../assest/products/i0nscan-600.png"),
            require("../../assest/products/i0nscan-600.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 43,
        title: "LCD 4",
        subTitle: "Compact, wearable Chemical Detector",
        description: "A rugged and reliable chemical-warfare agent identifier and TIC detector, the LCD is the most widely deployed personal chemical detector in use by armed forces globally.",
        Overview: [
            "Based on leading Ion Mobility Spectrometry (IMS) technology, the LCD 4 is an advanced warning device, that alarms to gas and vapour threats detected and identified at or below immediately dangerous to life and health (IDLH) levels, by determining the agent or type, class, concentration and dosage of chemical exposure. It can also be used as a screening and survey device.",
            "Flexible mounting options allow the LCD 4 to be handheld or clipped to a belt, harness or shoulder strap, to enable the user to undertake their primary role without obstruction. Features include both audible and visual alarms plus a clear, easy-to-read liquid crystal display.",
            "This device is very simple to operate and requires no calibration or complicated routine maintenance. The impressive performance of the LCD 4 combined with its ergonomic design and functionality provides a wide range of user capability while ensuring a minimal logistic burden.",
            "LCD 4 can record up to 72 hours of mission data for future analysis and features RS232 connectivity for data downloads and post-mission analysis. The device is designed to meet the requirements of MIL-STD-810 and MIL-STD-461.",
            "New product extensions and accessories extend the capabilities of the LCD 4, including wireless connectivity and detection of trace explosive, narcotics including fentanyls, and novichok chemical agents.",
            "LCD 4 is based on the LCD 3.3, selected by US DoD for the Joint Chemical Agent Detector (JCAD) programme. The LCD 4 is backed by first-rate service, training and support to ensure optimum product performance",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/LCD 4-1.pdf",
            "../../../public/pdf/LCD 4-2.pdf"
        ],
        Technical_Specification: [
            "Combined CWA and TICs detection library",
            "Up to 75 hours continuous use from a single set of commercial AA batteries",
            "Operates reliably in extreme environments",
            "Uses advanced, non radioactive IMS technology",
            "Enhanced with new communications and trace detection extensions",
        ],
        images: [
            require("../../assest/products/lcd.png"),
            require("../../assest/products/lcd.png"),
            require("../../assest/products/lcd.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 44,
        title: "MailGuard System",
        subTitle: "Complete mail room biothreat detection solution",
        description: "Offering rapid, biological aerosol collection and identification using forced airflow within a downdraft booth.",
        Overview: [
            "The BioFlash®MailGuard System, is a ready to use mail screening solution for both packages and envelopes. Incorporating CANARY™ technology, the system provides rapid, sensitive, and specific identification of biological warfare agents that may be present in mail or packages coming into your facility.",
            "The MailGuard incorporates the BioFlash®biological identifier, which detects and identifies specific biological agents with no need for sample manipulation or user interpretation. An easy-to-read LCD display provides test results immediately after analysis.",
            "The complete system includes all components needed for operation including the BioFlash® pathogen identifier, downdraft booth, mail jogger, and compact refrigerator. And can connect to the Internet via an optional tethered laptop computer, which permits remote system diagnostics. Option for remote monitoring of test results with quarterly system reporting.",
            "First-rate service, training, and support ensures optimum product performance is available when purchasing this product.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/MailGuard.pdf",
        ],
        Technical_Specification: [
            "Captures particles up to 10 microns in size",
            "Low operational and maintenance costs",
            "Used by top government and commercial mailrooms",
            "Easy to use and decontaminate",
            "PCR levels of sensitivity and specificity",
            "Extremely low false alarm rates",
        ],
        images: [
            require("../../assest/products/MailGuard.png"),
            require("../../assest/products/MailGuard.png"),
            require("../../assest/products/MailGuard.png"),
        ],
        category: "X-RAY SCREENING",
    },

    {
        id: 45,
        title: "MatriX Server",
        subTitle: "System management and image distribution system",
        description: "Connecting X-ray units and operator workstations, the MatriX Server helps to manage the distribution of images and results within a multi-level X-ray network.",
        Overview: [
            "The MatriX Server enables networking functions and software applications. It is the essential core of any networking solution in many different market sectors, including ports and borders, urban security, defence and, of course, aviation.",
            "In aviation, the major applications supported by the server are system management and X-ray image distribution for hold baggage and checkpoint screening – driven by advanced screening and management platforms such as Checkpoint.Evoplus.",
            "It facilitates everything from small networks and functions such as centralised screening and real-time management data, to linking large, international airport groups. Up to 600 X-ray scanners can be supported by one MatriX Server.",
            "Each system is assembled and configured according to individual customer requirements and tested to ensure turn-key operation. Premium components and commercially available standard hardware, deliver excellent reliability.",
            "Additional features include extended temperature range; spatial redundancy solutions; single mode optical fibre technology; and external data interfaces to support risk-based screening and remote maintenance.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/MatriX -1.PDF",
            "../../../public/pdf/MatriX 2.PDF",
            "../../../public/pdf/MatriX 3.PDF",
        ],
        Technical_Specification: [
            "Delivered complete, pre-configured and ready to go",
            "A choice of sizes and functions to meet your requirements",
            "Designed with a focus on advanced cyber security",
            "Optimum image distribution and system control",
            "Supports system management, statistics, reports and TIP",
            "Central image storage",
        ],
        images: [
            require("../../assest/products/MatriX Server.png"),
            require("../../assest/products/MatriX Server.png"),
            require("../../assest/products/MatriX Server.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 46,
        title: "MUX",
        subTitle: "Networking systems",
        description: "MUX links the CTX systems with user and control interfaces for remote screening of checked baggage.",
        Overview: [
            "MUX allows multiple operators to view bags from single or multiple explosives detection systems, resulting in more efficient screening and better use of personnel.",
            "With built-in redundancy to ensure network dependability, leading industry-standard switches and servers, and hot-swappable components to minimize downtime, our networking solutions help to increase security and to lower operational costs – while optimizing the use of critical personnel. Designed to be flexible and highly customizable, our infrastructure future-proofs your investment as your airport’s needs grow and change.",
            "It is highly customizable to fit the exact needs of any airport, and flexible enough to accommodate new regulations or passenger growth.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/MUX-1.pdf",
            "../../../public/pdf/MUX-2.pdf"
        ],
        Technical_Specification: [
            "Scalable, flexible solution for networking explosive detection systems",
            "Highly customisable, site-specific network design",
            "Multiple redundancies to minimise points of failure",
            "Hot-swappable components with automatic failover",
            "Centralised data collection and seamless integration of 2D and 3D images",
        ],
        images: [
            require("../../assest/products/MUX.png"),
            require("../../assest/products/MUX.png"),
            require("../../assest/products/MUX.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 47,
        title: "People screening with automatic detection",
        subTitle: "Non-invasive, automatic multi-material detection of concealed objects",
        description: "People screening systems provide both, automated detection of contraband and full privacy of persons being screened.",
        Overview: [
            "Millimetre-wave people screening solutions provide automatic multi-material detection of concealed objects, such as metals, ceramics, plastics, liquids or narcotics. The system immediately indicates concealed object locations with a marker shown on a representation of the person being screened. This allows for fast and targeted inspection, which results in increased security and throughput, and an improved passenger and operator experience.",
            "Used at security checkpoints in airports, corporate buildings and public venues around the globe, these non-invasive solutions allow for complete privacy and anonymity while helping to reveal concealed materials.",
            "We are world-wide experts in deploying various technologies including partner systems to enable customers to achieve both layered security solutions and operational efficiencies. Our Open Architecture approach allows for the easy integration of third party devices into any current checkpoint configuration as well as into our central screening & management solutions.",
        ],
        Featured_Highlights: [
            "", ""
        ],
        Product_downloads: [
            "", ""
        ],
        Technical_Specification: [
            "Ensures a less intrusive screening process by reducing the need for manual searches",
            "Uses a generic mannequin representation of the person to maintain privacy",
        ],
        images: [
            require("../../assest/products/People screening with automatic detection.png"),
            require("../../assest/products/People screening with automatic detection.png"),
            require("../../assest/products/People screening with automatic detection.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 48,
        title: "ScanVan",
        subTitle: "Mobile X-ray inspection system",
        description: "Customized to meet user needs, the ScanVan integrates Smiths Detection's HI-SCAN X-ray technology to screen freight, luggage or mail.",
        Overview: [
            "A self-contained, mobile system for immediate and flexible screening anywhere temporary or additional security measures are needed. Ideal for on-the-spot security screening at ports and borders, airports, concerts, sports arenas, conference centres, campuses and theme parks.",
            "At its core, ScanVan has a high powered, X-ray scanner for checking a wide-range of objects such as cargo, electronics, boxes, backpacks, luggage, baby buggies and much more. The vehicles include all the equipment needed to control the scanning process plus a powerful, motorised conveyor for easy loading and unloading. They can be driven without a commercial license.",
            "Available according to geographical location, there are two models –  ScanVan 8585 and ScanVan 100100 PRO.",
            "ScanVan 8585",
            "Available in Europe and across the world (excluding USA), this model is built as standard on the Mercedes Benz Sprinter 315 CDI with the option to choose an Iveco Daily 40C15. It features an HI-SCAN 8585 scanner as well as an air-conditioned control-room and is powered by either an external source or the optional on-board generator.",
            "The HI-SCAN 8585 has a large tunnel measuring 853 x 856 mm. The high-quality X-ray can penetrate up to 31mm of steel.",
            "ScanVan 100100 PRO",
            "This model is specifically geared towards the USA market and is built on a Ram ProMaster® cargo van with integrated HI-SCAN 100100 scanner. It can be powered via an external supply or the on-board generator. The HI-SCAN 100100 tunnel is an impressive 1010 x 1010mm to accommodate larger objects. The high-quality X-ray can penetrate up to 35mm of steel.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/ScanVan-1.PDF",
            "../../../public/pdf/ScanVan-2.pdf"
        ],
        Technical_Specification: [

            "Mobile, flexible security screening",
            "Scans wide range of objects",
            "Powerful X-ray generator",
            "Penetration up to 35mm steel",
            "On-board power generator",
            "Insulation and air-conditioning",
        ],
        images: [
            require("../../assest/products/ScanVan.png"),
            require("../../assest/products/ScanVan.png"),
            require("../../assest/products/ScanVan.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 49,
        title: "SDX 100100 DV",
        subTitle: "Advanced dual-view scanners for large objects",
        description: "The SDX 100100 DV series delivers high throughput break-bulk freight and oversized baggage screening",
        Overview: [
            "The SDX 100100 is especially designed for screening oversized baggage and break-bulk freight, accommodating items up to 100 x 100 cm in size. With a 200 kg conveyor load, it efficiently supports the screening of break-bulk cargo, and its tunnel easily handles out-of-gauge baggage.",
            "The ergonomic high conveyor of the SDX 100100 HC allows convenient manual handling of inspected objects, while the low installation height of the SDX 100100 LC conveyor belt makes the connection of supplementary feed- and/or discharge conveyor systems for heavy freight simple. ",
            "Equipped with a 160 kV generator, the SDX 100100 delivers powerful penetration capabilities and outstanding image quality.",
            "The SDX 100100 can be easily integrated both physically—into external material handling systems—and digitally, into an intelligent network that enables Central Image Processing.",
            "With new hardware and HiTraX 3 software, it offers best-in-class image quality, enhancing operational efficiency. The system’s modular design is optimised for the use of common parts, ensuring ease of manufacture, service, and operation.",
            "The SDX 100100 can be further enhanced with Smiths Detection’s iCMORE automated threat detection software, offering advanced detection of weapons and lithium batteries to increase security.",
            "Additionally, the option for the low installation height of the conveyor belt allows for easy connection of supplementary feed and/or discharge conveyor systems, making it ideal for handling heavy freight. The SDX 100100 is especially suited to meet the needs of airports, customs facilities, and parcel services.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/SDX 100100-1.pdf",
            "../../../public/pdf/SDX 100100-2.pdf"
        ],
        Technical_Specification: [
            "Excellent detection capabilities, exceeding international standards by far",
            "Latest hardware and software configuration for optimal performance and future-proofing",
            "Flexible integration options for adaptation to various applications",
            "AI-based iCMORE option for enhanced threat detection, including lithium batteries and weapons",
            "Option for rugged, low conveyor design, allowing for the easy handling of heavy goods",
            "Wire resolution: Up to AWG 40",
            "Steel penetration: Up to 37 mm",
        ],
        images: [
            require("../../assest/products/SDX 100100 DV.png"),
            require("../../assest/products/SDX 100100 DV.png"),
            require("../../assest/products/SDX 100100 DV.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 50,
        title: "SDX 10060 XDi",
        subTitle: "Advanced screening using X-ray Diffraction technology",
        description: "Providing highly accurate material discrimination and substance identification, the SDX 10060 XDi allows for exceptional detection of powders, liquids, and solids.",
        Overview: [
            "The Smiths Detection SDX 10060 XDi leverages cutting-edge X-ray Diffraction (XRD) technology to offer unparalleled material discrimination and substance identification capabilities. By generating a unique 'diffraction fingerprint' for molecular structures, it can accurately distinguish between substances with similar densities, making it exceptionally effective for identifying homemade explosives and narcotics in various forms. This advanced detection system is especially beneficial for high-volume, high-speed applications such as hold baggage screening at airports and parcel processing, where it enhances security and operational efficiency.",
            "The SDX 10060 XDi's ability to integrate with existing material and baggage handling systems allows it to handle alarm queries from multiple Level 1 scanners, significantly reducing manual checks and the overall false alarm rate. This automation not only saves substantial operational costs but also minimises human error. It is designed to meet ECAC 3.1, 3.2 and TSA 7.2 and is also one of the few technologies likely to detect evolving threats and therefore meet future regulatory standards. The detection library will evolve over time ensuring your security operation remains future-proofed. Furthermore, its specialised detection capabilities are invaluable for customs agencies, offering adaptable and up-to-date screening for prohibited items, including narcotics.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/XDi-2.pdf",
            "../../../public/pdf/XDi-1.pdf",
            "../../../public/pdf/XDi-3.pdf",
            "../../../public/pdf/XDi-4.pdf",
        ],
        Technical_Specification: [
            "Superior detection of specific materials, including homemade explosives.",
            "Enhanced efficiency through automated handling of most alarm situations.",
            "Significant reduction in false alarm rates.",
            "Reduction in manual oversight leads to lower operating costs.",
            "Advanced detection technology prepared for future security challenges.",
            "Precise identification of narcotics and prohibited items with adaptability.",
        ],
        images: [
            require("../../assest/products/SDX 10060 XDi.png"),
            require("../../assest/products/SDX 10060 XDi.png"),
            require("../../assest/products/SDX 10060 XDi.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 51,
        title: "SDX 6040",
        subTitle: "Redefining Mobility, Single-View Imaging, and Precision Detection",
        description: "Designed for the many and varied operations requiring fast, effective screening of personal belongings and small parcels.",
        Overview: [
            "The SDX 6040 is engineered for environments where a combination of high-quality imagery and mobility is essential. With a slim footprint of 80 cm in width and equipped with larger casters, it offers swift deployment across various locations. Its lightweight design allows for quick adaptation to changing security needs, making it ideal for high-traffic areas like power plants, transport networks, government and corporate buildings, visitor attractions, prisons, cruise ships, sports arenas/venues, and world heritage sites.",
            "Equipped with a 160 kV generator, the SDX 6040 delivers  X-ray images for accurate threat detection. The system includes smart image display features such as organic stripping and enhancement modes, providing clear differentiation between organic and inorganic materials. The optimised background contrast function minimises eye strain, supporting long-term operator vigilance.",
            "The SDX 6040 eliminates blind spots, offering a 100% view of scanned items and is compatible with the iCMORE  AI Prohibited Items technology, which offers automated threat detection for a wide range of prohibited items including pistols, knives, and grenades, ensuring comprehensive threat detection.",
            "Built with advanced electronics and the HiTraX 3 software platform, the SDX 6040 is modular and upgradeable, designed to meet future security challenges. Robust cybersecurity measures and features like Power-on Self-Test and Real-time System Feedback ensure the system's reliability and ease of maintenance. ",
            "The SDX 6040 is versatile, suitable for a wide range of sectors including power plants, transport networks, and public venues. It integrates seamlessly with other security technologies, providing a comprehensive solution for diverse security needs.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/SDX 6040.pdf",
        ],
        Technical_Specification: [
            "Superior image quality & smart display features",
            "100% view of scanned item",
            "Accurate detection",
            "Potential threats quickly identified",
            "Fully mobile (80cm wide)",
            "Excellent cybersecurity",
            "Backed by Smiths Detections global service network",
        ],
        images: [
            require("../../assest/products/SDX 6040.png"),
            require("../../assest/products/SDX 6040.png"),
            require("../../assest/products/SDX 6040.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 52,
        title: "Sneeze Guards",
        subTitle: "Protection screens",
        description: "Sneeze guards protect your staff and passengers alike from the risk of airborne disease transmission in areas where passengers and staff are in close proximity, such as recheck.",
        Overview: [
            "Sneeze guards protect your staff and passengers alike from the risk of airborne disease transmission in areas where passengers and staff are in close proximity, such as recheck.",
            "This simple solution provides safety and peace of mind for passenger and operators alike at security checkpoint. While the glass like transparency, providing maximum visibility of people and activity on either side.",
            "Our sneeze guards are made to fit your checkpoint design to ensure they’re fit for purpose.",
        ],
        Featured_Highlights: [
            "", ""
        ],
        Product_downloads: [
            "", ""
        ],
        Technical_Specification: [
            "Physical barrier reducing the risk of airborne disease transmission",
            "Easy upgrade of existing tray handling systems",
            "Tailored to fit",
            "Provides safety and peace of mind",
            "Transparency helps maintain human connection",
        ],
        images: [
            require("../../assest/products/Sneeze Guards.png"),
            require("../../assest/products/Sneeze Guards.png"),
            require("../../assest/products/Sneeze Guards.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 53,
        title: "Target-ID",
        subTitle: "Mobile illicit-drug identification device",
        description: "Using Fourier Transform Infrared spectroscopy technology, the Target-ID identification system quickly identifies illicit narcotics and controlled substances in the field.",
        Overview: [
            "Combining the power, speed, and accuracy of FTIR with a library of up to 2,500 narcotics, precursors, cutting agents and common chemicals, Target-ID returns identification results in a matter of seconds without damaging or degrading the sample.",
            "Target-ID can identify powders, liquids, gels, pastes and solids. In addition, emerging designer drugs, and local variants can be added to its expandable user library to identify new or emerging threats.",
            "The device is lightweight, easily transported, and provides four hours of operation on a single battery charge. It also accepts commercially available disposable batteries or line power.",
            "Personnel of varying skill levels will find the software interface intuitive and easy to navigate.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/Target-ID-1.pdf",
            "../../../public/pdf/Target-ID-2.pdf"
        ],
        Technical_Specification: [
            "Uses proven FTIR technology",
            "Lightweight and portable for hand-held operation",
            "Pre-loaded library of up to 2,500 substances",
            "Intuitive user interface requiring minimal training",
        ],
        images: [
            require("../../assest/products/Target-ID.png"),
            require("../../assest/products/Target-ID.png"),
            require("../../assest/products/Target-ID.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 54,
        title: "UrbanAware",
        subTitle: "An advanced CBRN (Chemical, Biological, Radiological, and Nuclear) simulation and modelling software solution",
        description: "",
        Overview: [
            "Urban Aware is an advanced CBRN (Chemical, Biological, Radiological, and Nuclear) simulation and modelling software solution developed through a strategic partnership between Smiths Detection and Riskaware. UrbanAware revolutionises the way chemical hazard intelligence is delivered, especially in high-stakes, urban environments.",
            "By seamlessly integrating Riskaware’s cutting-edge CBRN/HazMat Information Management (IM) system with Smiths Detection’s trusted LCD 4 chemical detector, UrbanAware collects, analyses, and delivers real-time incident intelligence directly to first responders and military planners. This state-of-the-art platform enhances decision-making at the tactical edge by closing the gap between data collection, analysis, and strategic awareness.",
            "UrbanAware supports faster, more informed response strategies, making it an essential solution for defence and security organisations. Whether managing a chemical incident or preparing for future threats, UrbanAware is designed to protect people, environments, and infrastructure while addressing global challenges.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/UrbanAware.pdf",
        ],
        Technical_Specification: [
            "Real-time Chemical Hazard Detection: Alerts are triggered through sensors, enabling quick identification and response to hazardous threats in the field.",
            "Tactical Intelligence Delivery: Threat analysis results are sent to ruggedised mobile devices with clear visualisations, including threat mapping relative to team positions.",
            "Advanced Predictive Modelling: With built-in simulation capabilities, UrbanAware can forecast the potential next stages of a chemical attack or industrial release, aiding in evacuation planning and cordon establishment.",
            "Urban Optimisation: Designed specifically for complex urban environments, the platform accounts for the effects of streets, buildings, and population dynamics on hazard dispersion.",
            "Proven Technology: Riskaware's CBRN tools, validated in real-world scenarios, were originally developed by the Defence Science and Technology Laboratory (Dstl).",
        ],
        images: [
            require("../../assest/products/UrbanAware.png"),
            require("../../assest/products/UrbanAware.png"),
            require("../../assest/products/UrbanAware.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 55,
        title: "UV light modules",
        subTitle: "Automatic tray disinfection using UV light",
        description: "With the ability to kill up to 99.9% of bacteria and viruses, the UV-C kits for airports help protect passengers and staff from tactile transmission of contagious disease.",
        Overview: [
            "Short-wavelength UV light (UVC) has been successfully used for the disinfection of surfaces in industries with high hygiene requirements, such as the food and health sectors, for many years. It destroys nucleic acids and disrupts the DNA of microorganisms, leaving them unable to perform vital cellular functions.",
            "With the ability to kill up to 99.9% of bacteria and viruses, the UVC kits help protect passengers and staff from tactile transmission of contagious disease.",
            "We are using this proven technology in our UVC light tray disinfection kit, which can be retrofitted into existing automated tray handlings systems. Our kits have been designed to automatically kill bacteria and viruses on trays as they’re being transported from reclaim back to the divest station without affecting the overall speed of the tray return system, the length of the lane or the operational efficiency.",
            "For checkpoints with passive conveyor systems we have developed a mobile standalone solution.",
            "As UV-C light can be harmful, the kits are safely shielded from any leakage using robust metal housing and following relevant safety standards such as BS EN ISO 15858:2016. Interlocks automatically shut off the UV-C lamps in the event of an emergency stop, tray blockages on the conveyor or opening the module.",
        ],
        Featured_Highlights: [
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
            require("../../assest/images/pdf.png"),
        ],
        Product_downloads: [
            "../../../public/pdf/UV light modules-1.pdf",
            "../../../public/pdf/UV light modules-2.pdf",
            "../../../public/pdf/UV light modules-3.pdf",
        ],
        Technical_Specification: [
            "Eliminate up to 99.9% of bacteria and viruses on trays",
            "Easy to fit into existing tray handling systems, regardless of vendor",
            "No impact on operational efficiency",
            "Mobile standalone version available",
            "Optional system status monitoring",
            "Protection from exposure to UV light following relevant safety standards",
        ],
        images: [
            require("../../assest/products/UV light modules.png"),
            require("../../assest/products/UV light modules.png"),
            require("../../assest/products/UV light modules.png"),
        ],
        category: "X-RAY SCREENING",
    },
    {
        id: 56,
        title: "ViZual",
        subTitle: "Organic/inorganic material discrimination",
        description: "Imaging software that provides organic/inorganic material discrimination, and colour differentiation between inspected objects.",
        Overview: [
            "viZual imaging software helps to distinguish unexpected loads by adding colour – linked to the atomic number – to the detailed radioscopic image of the container or vehicle, for rapid and reliable results in a single scan.",
            "viZual can discriminate and highlight foreign objects such as explosives, drugs and plastics hidden among other cargo in cars, lorries and containers.",
            "It also allows the operator to distinguish objects such as firearms, hidden in or among organic objects.",
            "It can also be combined with the ARD automatic radioactive-material detection system to carry out simultaneous X-ray inspection and nuclear analysis.",
        ],
        Featured_Highlights: [
            "", ""
        ],
        Product_downloads: [
            "", ""
        ],
        Technical_Specification: [
            "colourization",
        ],
        images: [
            require("../../assest/products/ViZual.png"),
            require("../../assest/products/ViZual.png"),
            require("../../assest/products/ViZual.png"),
        ],
        category: "X-RAY SCREENING",
    },

];

export default productData;