import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import productData from "../data/productData";

export default function ProductsDetails() {
  const { t } = useTranslation();
  const { id } = useParams(); // Extract product ID from URL
  const product = productData.find((el) => el.id === parseInt(id)); // Find product by ID

  if (!product) {
    return (
      <div className="text-white text-center">{t("Product not found")}</div>
    );
  }

  return (
    <>
      <section className="hearder-pages relative border-b-4 border-b-[#0093dd]">
        <div className="btmAni30002 h-full text-white flex items-center justify-center f-title text-5xl font-bold uppercase relative gap-[20px]">
          <div className="flex flex-col gap-[30px]">
            <span className="ang-1 w-[20px] h-[20px] flex bg-transparent border-t border-l border-t-[1px] border-l-[1px] border-transparent border-t-white border-l-white"></span>
            <span className="ang-2 w-[20px] h-[20px] flex bg-transparent border-b border-b border-b-[1px] border-l-[1px] border-transparent border-b-white border-l-white"></span>
          </div>
          {t("products details")}
          <div className="flex flex-col gap-[30px]">
            <span className="ang-3 w-[20px] h-[20px] flex bg-transparent border-t border-r border-t-[1px] border-r-[1px] border-transparent border-t-white border-r-white"></span>
            <span className="ang-4 w-[20px] h-[20px] flex bg-transparent border-b border-r border-b-[1px] border-r-[1px] border-transparent border-b-white border-r-white"></span>
          </div>
        </div>
        <div className="d-none animtion-pages absolute -left-[300px] -top-[36px] transition-all duration-500">
          <img src={require("../../assest/images/animation.png")} alt="" />
        </div>
      </section>
      <section className="relative py-[40px]">
        <div className="container flex flex-col gap-[20px]">
          <div className="flex justify-between items-center gap-[50px]">
            <div className="w-[70%] h-full flex flex-col gap-[10px]">
              <div className="border-left-none font-bold text-white text-[42px] f-29 uppercase border-l-[12px] border-l-[#0093dd] pl-[10px]">
               {t(`${product.title}`)}
              </div>
              <div className="font-bold text-white text-[25px] pl-[10px]">
                {t(`${product.subTitle}`)}
              </div>
              <div className="padding-right-10 text-white font-light text-[20px] pl-[10px]">
                {t(`${product.description}`)}
              </div>
            </div>
            {/* Slider Section */}
            <div className="w-[30%]">
              <div>
                <img
                  src={product.images[0]} 
                  alt="Product"
                  className="w-full h-[300px] object-cover rounded-md"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-[50px]">
            <div className="font-bold text-white text-[35px] uppercase pl-[10px]">
              {t("Overview")}
            </div>
            <div className="text-white font-light text-[20px] pl-[10px] flex flex-col gap-[10px]">
              {product.Overview.map((paragraph, idx) => (
                <p key={idx}>{t(`${paragraph}`)}</p>
              ))}
            </div>
            <div className="font-bold text-white text-[35px] uppercase pl-[10px]">
              {t("Technical Specifications")}
            </div>
            <div className="text-white font-light text-[20px] pl-[10px]">
              <ul className="list-disc list-inside">
                {product.Technical_Specification.map((spec, idx) => (
                  <li key={idx}>{t(`${spec}`)}</li>
                ))}
              </ul>
            </div>
            <div className="flex items-center gap-[10px]">
              {product.Product_downloads.map((pdf, idx) => (
                <Link
                  key={idx}
                  to={`/pdf/${pdf.split("/").pop()}`} // Correct path relative to public folder
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="download-button">
                    <span className="text-white font-bold text-[25px] underline py-[5px]">{t(`${product.title}`)}</span> {/* Replace 'product.name' with the actual product name */}
                  </button>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
