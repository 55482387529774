import vedioS from "../../assest/vedios/hi-tech.mp4";
import vedio2 from "../../assest/vedios/3.mp4";
const blogData = [
    {
        id: 1,
        image: "", // No image provided for this post
        video: vedioS, // Use path from `public` folder
        title: "Tom Squire | CEO of SDME",
        para: "",
    },
    {
        id: 2,
        image: require("../../assest/images/blog1.jpg"),
        title: "Security conference",
        para: "Issue 7 2022, Technews Publishing, Residential Estate (Industry) The first Residential Estate Security Conference since March 2020 discussed the people, technology and processes involved in mitigating risks to deliver smart, secure living.",
    },
    {
        id: 3,
        image: "", // No image provided for this post
        video: vedio2, // Use path from `public` folder
        title: "",
        para: "",
    },
    {
        id: 4,
        image: require("../../assest/images/3.jpg"),
        title: "Security conference",
        para: "",
    },
];

export default blogData;
