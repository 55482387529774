import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from './component/main/Header';
import Footer from './component/main/Footer';
import About from "./component/pages/About";
import Care from "./component/pages/Care";
import Careers from "./component/pages/Careers";
import Ceo from "./component/pages/Ceo";
import Clients from "./component/pages/Clients";
import Contact from "./component/pages/Contact";
import Home from "./component/pages/Home";
import Legal from "./component/pages/Legal";
import Privacy from "./component/pages/Privacy";
import Products from "./component/pages/Products";
import Experts from './component/pages/Experts';
import Services from "./component/pages/Services";
import Terms from "./component/pages/Terms";
import Back from './component/pages/Back';
import BlogAndNews from './component/pages/BlogAndNews';
import ProductsDetails from './component/pages/ProductsDetails';
import Portfolio from './component/pages/Portfolio';
import { Route, Routes } from 'react-router-dom';
import ScrollToTop from './component/ScrollToTop';
import Top from './component/Top';
import Loader from './component/Loader';


const App = () => {
  const { i18n } = useTranslation();

  React.useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'en';
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  i18n.on('languageChanged', (lng) => {
    document.documentElement.lang = lng;
    document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
    localStorage.setItem('language', lng);
  });

  return (
    <>
      <Header />
      <Top/>
      <Loader/>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/services' element={<Services />} />
        <Route path='/products' element={<Products />} />
        <Route path='/client' element={<Clients />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/legal' element={<Legal />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/careers' element={<Careers />} />
        <Route path='/ceo' element={<Ceo />} />
        <Route path='/care' element={<Care />} />
        <Route path='/back' element={<Back />} />
        <Route path='/blog' element={<BlogAndNews />} />
        <Route path="/details/:id" element={<ProductsDetails />} />
        <Route path='/portfolio' element={<Portfolio />} />
        <Route path='/experts' element={<Experts/>}  />
      </Routes>

      <ScrollToTop />
      <useScrollAnimation />
      <Footer />
    </>
  );
};

export default App