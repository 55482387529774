import React from "react";
import { useTranslation } from 'react-i18next';
import blogData from "../data/blogData";
import useScrollAnimation from "../../hooks/useScrollAnimation";

export default function BlogAndNews() {
    const { t } = useTranslation();
    useScrollAnimation();

    const showData = blogData.map((el, index) =>
        <div key={index} className="js-scroll fade-top bgLinearTwo min-h-[615px] w-full sm:w-[48%] p-[35px] rounded-[8px] flex flex-col gap-[15px]">
            <div className="h-[314px]">
                {el.video ? (
                    // Render video if `el.video` is present
                    <video className="h-[314px]" width="600" controls>
                        <source src={el.video} type="video/mp4" />
                    </video>
                ) : (
                    // Render image if no video
                    <img src={el.image} alt={el.title} />
                )}
            </div>
            <div className="capitalize text-[20px] text-white">{t(`${el.title}`)}</div>
            <p className="text-white font-light">{t(`${el.para}`)}</p>
            <div className="h-[2px] bg-white w-full"></div>
        </div>
    );

    return (
        <>
            <section className="hearder-pages relative border-b-4 border-b-[#0093dd]">
                <div className="btmAni30002 h-full text-white flex items-center justify-center f-title text-5xl font-bold uppercase relative gap-[20px]">
                    <div className="flex flex-col gap-[30px]">
                        <span className="ang-1 w-[20px] h-[20px] flex bg-transparent border-t border-l border-t-[1px] border-l-[1px] border-transparent border-t-white border-l-white"></span>
                        <span className="ang-2 w-[20px] h-[20px] flex bg-transparent border-b border-b border-b-[1px] border-l-[1px] border-transparent border-b-white border-l-white"></span>
                    </div>
                    {t("Blogs And News")}
                    <div className="flex flex-col gap-[30px]">
                        <span className="ang-3 w-[20px] h-[20px] flex bg-transparent border-t border-r border-t-[1px] border-r-[1px] border-transparent border-t-white border-r-white"></span>
                        <span className="ang-4 w-[20px] h-[20px] flex bg-transparent border-b border-r border-b-[1px] border-r-[1px] border-transparent border-b-white border-r-white"></span>
                    </div>
                </div>
                <div className="d-none animtion-pages absolute -left-[300px] -top-[36px] transition-all duration-500">
                    <img src={require("../../assest/images/animation.png")} alt="" />
                </div>
            </section>

            <section className="relative py-[40px]">
                <div className="container">
                    <div className="flex flex-wrap items-center justify-center gap-[20px] w-full">
                        {showData}
                    </div>
                </div>
            </section>
        </>
    );
}
