import React from 'react';
import "../assest/Loader.css";

const Loader = () => {
  return (
    <div className="loader">
      <div className="loaderLogo">
        <img src={require("../assest/logo/logo.svg").default} alt="" />
      </div>
      <img src={require("../assest/icons/loader.svg").default} alt="" />
    </div>
  );
};

export default Loader;
