import {
    SupportTeam,
    ExpertTechnicians,
    PreventativeMaintenance,
    EmergencyService,
    OnSiteRemoteSupport
} from "../../assest/icons/icons";

const technicalSupportData = [
    {
        id: 1,
        icons:<SupportTeam/>,
        title: "24/7 Support Team",
    },
    {
        id: 2,
        icons:<ExpertTechnicians/>,
        title: "Expert Technicians",
    },
    {
        id: 3,
        icons:<PreventativeMaintenance/>,
        title: "Preventative Maintenance",
    },
    {
        id: 4,
        icons:<EmergencyService/>,
        title: "Emergency Service",
    },
    {
        id: 5,
        icons:<OnSiteRemoteSupport/>,
        title: "On-site & Remote Support",
    },
    
];

export default technicalSupportData;
