const partnersData = [
    {
        id: 1,
        image:require('../../assest/partners/logo.svg').default,
        url : "https://www.smiths.com/",
        urlName : "www.smiths.com",
        title: "Security Screening Equipment",
    },
    {
        id: 2,
        image:require('../../assest/partners/2.png'),
        url : "https://www.moveenconnect.com/",
        urlName : "www.moveenconnect.com",
        title: "Baggage Handling System",
    },
    {
        id: 3,
        image:require('../../assest/partners/3.png'),
        url : "http://www.chanwanich.digital.com/",
        urlName : "www.chanwanich.digital.com",
        title: "Automated Immigration",
    },
    {
        id: 4,
        image:require('../../assest/partners/4.png'),
        url : "https://wamar.com/",
        urlName : "www.wamar.com",
        title: "Aviation, Aerospace, Defense",
    },
    {
        id: 5,
        image:require('../../assest/partners/5.svg').default,
        url : "https://cheetah.co.th/",
        urlName : "www.cheetah.co.th",
        title: " Airport Logistic | ASRS",
    },
    {
        id: 6,
        image:require('../../assest/partners/6.png'),
        url : "https://www.stsaviationgroup.com/",
        urlName : "www.stsaviationgroup.com",
        title: " Aircraft Maintenance Services",
    },
    {
        id: 7,
        image:require('../../assest/partners/7.png'),
        url : "https://www.storemaster.co.th/",
        urlName : "www.storemaster.co.th",
        title: " Automate Storage System",
    },
    {
        id: 8,
        image:require('../../assest/partners/8.png'),
        url : "https://www.hikrobotics.com/en/",
        urlName : "www.hikrobotics.com",
        title: " Video Coding | Machine Vision",
    },
    {
        id: 9,
        image:require('../../assest/partners/9.png'),
        url : "https://www.smcbot.com/",
        urlName : "www.smcbot.com",
        title: " Service Robot for Airport",
    },
    
];

export default partnersData;
