const portfolioData = [
    {
        id: 1,
        image: require("../../assest/portfolio/1.png"),
        title: "Jordan customs Aqaba port terminals",
        description: "Upgrade the customs and cargo handling operations at Aqaba Port to increase efficiency and security with advanced inspection systems."
    },
    {
        id: 2,
        image: require("../../assest/portfolio/2.jpg"),
        title: "King Hussein Airport expansion project in Aqaba",
        description: "Expand King Hussein Airport to accommodate increased passenger and cargo traffic, enhancing its role as a regional hub."
    },
    {
        id: 3,
        image: require("../../assest/portfolio/3.jpg"),
        title: "Amman Ritz Hotel",
        description: "Develop a luxury hotel in Amman, offering top-tier amenities and hospitality for international travelers and tourists."
    },
    {
        id: 4,
        image: require("../../assest/portfolio/4.jpg"),
        title: "Jordan Airports company cargo inspection system",
        description: "Install advanced cargo inspection systems at Jordan’s airports to enhance security and streamline processes."
    },
    {
        id: 5,
        image: require("../../assest/portfolio/5.jpg"),
        title: "Royal Jordanian Cargo Security",
        description: "Enhance cargo security for Royal Jordanian by deploying advanced systems and ensuring compliance with international standards."
    },
]

export default portfolioData;