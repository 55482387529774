import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
export default function Back() {
    const { t } = useTranslation();
    return (
        <section className="relative py-[40px]">
            <div className="container flex flex-col justify-center items-center gap-[30px]">
                <div className="btmAni25002">
                    <img className="w-[260px]" src={require("../../assest/logo/logo.svg").default} alt="" />
                </div>
                <div className="btmAni20002 capitalize text-[30px] font-bold text-white">{t("Thank you for applying for our position.")}</div>
                <Link className="btmAni3000 js-scroll fade-top custom-btn btn-3 capitalize text-center" to={"/"} >
                    <span>{t("Back To Home")}</span>
                </Link>
            </div>
        </section>
    );
}