import React from "react";
import { useTranslation } from 'react-i18next';
import servicesData from "../data/servicesData";
import useScrollAnimation from "../../hooks/useScrollAnimation";
export default function Services() {
    const { t } = useTranslation();

    useScrollAnimation();

    const dataShow = servicesData.map((el,index) =>
        <div key={index} className="js-scroll fade-top width-100 bgLinear w-[30%] h-[300px] p-[20px] rounded-[8px] flex flex-col gap-[12px]">
            <div className="w-[90px] h-[90px] bg-[rgba(0,147,221,0.1)] rounded-full flex items-center justify-center">
            {el.icon}
            </div>
            <div className="text-white text-[22px] font-bold">{t(`${el.title}`)}</div>
            <p className="text-white">
                {t(`${el.para}`)}
            </p>
        </div>)
    return (
        <>
            <section className="hearder-pages relative border-b-4 border-b-[#0093dd]">
                <div className="btmAni30002 h-full text-white flex items-center justify-center f-title text-5xl font-bold uppercase relative gap-[20px]">
                    <div className="flex flex-col gap-[30px]">
                        <span className="ang-1 w-[20px] h-[20px] flex bg-transparent border-t border-l border-t-[1px] border-l-[1px] border-transparent border-t-white border-l-white"></span>
                        <span className="ang-2 w-[20px] h-[20px] flex bg-transparent border-b border-b border-b-[1px] border-l-[1px] border-transparent border-b-white border-l-white"></span>
                    </div>
                    {t("our services")}
                    <div className="flex flex-col gap-[30px]">
                        <span className="ang-3 w-[20px] h-[20px] flex bg-transparent border-t border-r border-t-[1px] border-r-[1px] border-transparent border-t-white border-r-white"></span>
                        <span className="ang-4 w-[20px] h-[20px] flex bg-transparent border-b border-r border-b-[1px] border-r-[1px] border-transparent border-b-white border-r-white"></span>
                    </div>
                </div>
                <div className="d-none animtion-pages absolute -left-[300px] -top-[36px] transition-all duration-500">
                    <img src={require("../../assest/images/animation.png")} alt="" />
                </div>
            </section>
            <section className="relative py-[40px]">
                <div className="container flex flex-col gap-[50px] justify-between">
                    <div className="border-left-none btmAni30002 font-bold text-white text-[42px] f-29 uppercase border-l-[12px] border-l-[#0093dd] pl-[10px]">{t("Our Range of Solutions")}</div>
                    <div className="btmAni25002 padding-right-10 text-white font-light text-[25px] pl-[10px]">
                        {t("services content")}
                    </div>
                    <div className="f-col flex flex-wrap items-center justify-center gap-[20px]">
                        {dataShow}
                    </div>
                </div>
            </section>

        </>
    );
}