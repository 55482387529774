import React from "react";
import { useTranslation } from 'react-i18next';
import servicesData from "../../data/servicesData";
import useScrollAnimation from "../../../hooks/useScrollAnimation";
export default function ServicesHomeSection() {
    const { t } = useTranslation();

    useScrollAnimation();
    const dataShow = servicesData.map((el,index) => 
        <div key={index} className="js-scroll fade-btm width-100 bgLinear w-[30%] h-[300px] p-[20px] rounded-[8px] flex flex-col gap-[12px]">
            <div className="w-[90px] h-[90px] bg-[rgba(0,147,221,0.1)] rounded-full flex items-center justify-center">
                {el.icon}
            </div>
            <div className="text-white text-[22px] font-bold">{t(`${el.title}`)}</div>
            <p className="text-white">
                {t(`${el.para}`)}
            </p>
        </div>)
    return (
        <section className="relative py-[40px]">
            <div className="js-scroll fade-top main-title">{t("our services")}</div>
            <div className="container flex flex-col items-center gap-[50px] justify-between">
                <div className="js-scroll fade-btm text-white font-light text-[25px] text-center">
                    {t("services content")}
                </div>
                <div className="flex flex-wrap items-center justify-center gap-[20px]">
                    {dataShow}
                </div>
            </div>
        </section>
    );
}