import {
    Airport,
    Baggage,
    Maintenance,
    Management,
    Solutions
} from "../../assest/icons/icons";
const servicesData = [
    {
        id:1,
        icon:<Airport/>,
        title : "Airport & Border Crossings Security",
        para : "Providing state-of-the-art security screening solutions and monitoring systems for airports and borders.",
    },
    {
        id:2,
        icon:<Baggage/>,
        title : "Baggage Handling Systems",
        para : "Efficient and reliable systems for baggage handling that optimize operational flow.",
    },
    {
        id:3,
        icon:<Solutions/>,
        title : "Airport Technology Solutions",
        para : "Cutting-edge technology for enhanced airport operations, including air traffic control and ground support.",
    },
    {
        id:4,
        icon:<Management/>,
        title : "Airport Supply Chain Management",
        para : "Streamlining the supply chain for airport facilities, ensuring smooth operations.",
    },{
        id:5,
        icon:<Maintenance/>,
        title : " Airport Facilities Maintenance",
        para : "Ongoing maintenance services for airport infrastructure and security systems.",
    },

]

export default servicesData;