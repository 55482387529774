import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useScrollAnimation from "../../hooks/useScrollAnimation";

export default function Careers() {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phone: '',
        jobPosition: '',
        age: '',
        major: '',
        personalInfo: '',
        message: '',
        cv: null
    });
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useScrollAnimation();

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle file input change
    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            cv: e.target.files[0]
        });
    };

    // Basic form validation
    const validateForm = () => {
        if (!formData.fullName || !formData.email || !formData.phone || !formData.jobPosition) {
            setError('Please fill out all required fields.');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) return;

        setError(''); // Reset error message
        const data = new FormData();
        data.append('fullName', formData.fullName);
        data.append('email', formData.email);
        data.append('phone', formData.phone);
        data.append('jobPosition', formData.jobPosition);
        data.append('age', formData.age);
        data.append('major', formData.major);
        data.append('personalInfo', formData.personalInfo);
        data.append('message', formData.message);
        data.append('cv', formData.cv);

        try {
            // Send the form data to the server
            const response = await fetch('https://email.petra-ms.com/careers.php', {
                method: 'POST',
                body: data,
            });

            // Ensure the response is valid JSON
            const result = await response.json();

            // If response is OK, proceed with success handling
            if (response.ok) {
                setSuccessMessage('Your application has been submitted successfully!');
                setFormData({
                    fullName: '',
                    email: '',
                    phone: '',
                    jobPosition: '',
                    age: '',
                    major: '',
                    personalInfo: '',
                    message: '',
                    cv: null,
                });

                // Redirect to the external site after 1 second delay
                setTimeout(() => {
                    window.location.href = "https://hitechdetection.com/"; // Redirect to external website
                }, 2500);

            } else {
                throw new Error(result.message || 'There was an error submitting your application.');
            }
        } catch (error) {
            console.error('Error:', error);
            setError(error.message || 'There was an error submitting your application.');
        }
    };



    return (
        <>
            <section className="hearder-pages relative border-b-4 border-b-[#0093dd]">
                <div className="btmAni30002 h-full text-white flex items-center justify-center f-title text-5xl font-bold uppercase relative gap-[20px]">
                    <div className="flex flex-col gap-[30px]">
                        <span className="ang-1 w-[20px] h-[20px] flex bg-transparent border-t border-l border-t-[1px] border-l-[1px] border-transparent border-t-white border-l-white"></span>
                        <span className="ang-2 w-[20px] h-[20px] flex bg-transparent border-b border-b border-b-[1px] border-l-[1px] border-transparent border-b-white border-l-white"></span>
                    </div>
                    {t("careers")}
                    <div className="flex flex-col gap-[30px]">
                        <span className="ang-3 w-[20px] h-[20px] flex bg-transparent border-t border-r border-t-[1px] border-r-[1px] border-transparent border-t-white border-r-white"></span>
                        <span className="ang-4 w-[20px] h-[20px] flex bg-transparent border-b border-r border-b-[1px] border-r-[1px] border-transparent border-b-white border-r-white"></span>
                    </div>
                </div>
                <div className="d-none animtion-pages absolute -left-[300px] -top-[36px] transition-all duration-500">
                    <img src={require("../../assest/images/animation.png")} alt="" />
                </div>
            </section>

            <section className="relative py-[40px]">
                <div className="container flex flex-col gap-[50px]">
                    <div className="flex flex-col gap-[50px]">
                        <div className="border-left-none font-bold text-white text-[42px] f-29 uppercase border-l-[12px] border-l-[#0093dd] pl-[10px]">{t("Current vacancies")}</div>
                        <div className="padding-right-10 flex items-center gap-[5px]">
                            <div className="bg-white p-[10px] rounded-[8px] font-bold">{t("There are no vacancies currently")}</div>
                        </div>
                    </div>

                    <div className="flex flex-col gap-[10x] items-center text-white">
                        <div className="js-scroll fade-top text-[30px] font-bold">{t("Thank you for your interest in joining our team!")}</div>
                        <p className="js-scroll fade-top text-white font-light text-[20px]">
                            {t("Please fill out the form below to submit your application for the desired position.")}
                        </p>
                        <p className="js-scroll fade-top text-white font-light text-[20px]">
                            {t("Make sure to provide accurate information and upload your CV in one of the accepted formats (PDF, DOC, or DOCX).")}
                        </p>
                    </div>

                    {/* Error and Success Messages */}
                    {error && <div className="text-red-500 text-xl">{error}</div>}
                    {successMessage && <div className="text-green-500 text-xl">{successMessage}</div>}

                    <form onSubmit={handleSubmit}>
                        <div className="flex flex-col gap-[20px]">
                            {/* Form Fields */}
                            <div className="flex flex-col gap-[20px]">
                                {/* Full Name and Email */}
                                <div className="js-scroll fade-left f-col g-15 flex items-center justify-between">
                                    <div className="js-scroll fade-left width-100 flex flex-col gap-[7px] w-[500px]">
                                        <label className="text-[20px] font-bold text-white capitalize">{t("Full Name")}</label>
                                        <input
                                            className="outline-0 border border-[#0093DD] p-2 bg-transparent text-white w-full rounded-lg"
                                            type="text"
                                            placeholder={t("Full Name")}
                                            name="fullName"
                                            value={formData.fullName}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="js-scroll fade-right width-100 flex flex-col gap-[7px] w-[500px]">
                                        <label className="text-[20px] font-bold text-white capitalize">{t("Your Email")}</label>
                                        <input
                                            className="outline-0 border border-[#0093DD] p-2 bg-transparent text-white w-full rounded-lg"
                                            type="email"
                                            placeholder={t("Your Email")}
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>
                                {/* Phone and Job Position */}
                                <div className="js-scroll fade-right f-col g-15 flex items-center justify-between">
                                    <div className="width-100 flex flex-col gap-[7px] w-[500px]">
                                        <label className="text-[20px] font-bold text-white capitalize">{t("Phone Number")}</label>
                                        <input
                                            className="outline-0 border border-[#0093DD] p-2 bg-transparent text-white w-full rounded-lg"
                                            type="text"
                                            placeholder={t("Phone Number")}
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="js-scroll fade-right width-100 flex flex-col gap-[7px] w-[500px]">
                                        <label className="text-[20px] font-bold text-white capitalize">{t("The Job You Are Applying For")}</label>
                                        <input
                                            className="outline-0 border border-[#0093DD] p-2 bg-transparent text-white w-full rounded-lg"
                                            type="text"
                                            placeholder={t("The Job You Are Applying For")}
                                            name="jobPosition"
                                            value={formData.jobPosition}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>
                                {/* Age, Major */}
                                <div className="js-scroll fade-top f-col g-15 flex items-center justify-between">
                                    <div className="width-100 flex flex-col gap-[7px] w-[500px]">
                                        <label className="text-[20px] font-bold text-white capitalize">{t("Age")}</label>
                                        <input
                                            className="outline-0 border border-[#0093DD] p-2 bg-transparent text-white w-full rounded-lg"
                                            type="number"
                                            placeholder={t("Age")}
                                            name="age"
                                            value={formData.age}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="js-scroll fade-left width-100 flex flex-col gap-[7px] w-[500px]">
                                        <label className="text-[20px] font-bold text-white capitalize">{t("Major")}</label>
                                        <input
                                            className="outline-0 border border-[#0093DD] p-2 bg-transparent text-white w-full rounded-lg"
                                            type="text"
                                            placeholder={t("Major")}
                                            name="major"
                                            value={formData.major}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                {/* Personal Info */}
                                <div className="js-scroll fade-top f-col g-15 flex items-center justify-between">
                                    <div className="width-100 js-scroll fade-left flex flex-col gap-[7px] w-[500px]">
                                        <label className="text-[20px] font-bold text-white capitalize">{t("Personal Information")}</label>
                                        <textarea
                                            className="outline-0 border border-[#0093DD] p-2 bg-transparent text-white w-full h-[300px] max-h-[320px] rounded-lg"
                                            placeholder={t("Personal Information")}
                                            name="personalInfo"
                                            value={formData.personalInfo}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    {/* Message */}
                                    <div className="width-100 js-scroll fade-top flex flex-col gap-[7px] w-[500px]">
                                        <label className="text-[20px] font-bold text-white capitalize">{t("Your Message")}</label>
                                        <textarea
                                            className="outline-0 border border-[#0093DD] p-2 bg-transparent text-white w-full h-[300px] max-h-[320px] rounded-lg"
                                            placeholder={t("Your Message")}
                                            name="message"
                                            value={formData.message}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                {/* Upload CV */}
                                <div className="js-scroll fade-left flex flex-col gap-[7px]">
                                    <label className="text-[20px] font-bold text-white capitalize">{t("Upload CV")}</label>
                                    <input
                                        type="file"
                                        className="bg-transparent text-white"
                                        onChange={handleFileChange}
                                    />
                                </div>
                            </div>
                            <button type="submit" className=" custom-btn btn-3 capitalizew-fit mt-[30px] p-2 bg-[#0093DD] text-white rounded-lg">
                                <span>{t("send")}</span>
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
}
