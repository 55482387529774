import React from "react";
import { useTranslation } from 'react-i18next';
import coreValuesData from "../data/coreValuesData";
import TechnicalSupport from "../sections/TechnicalSupport";
// import technicalSupportData from "../data/technicalSupportData";
import useScrollAnimation from "../../hooks/useScrollAnimation";
export default function About() {
    const { t } = useTranslation();

//     <div className="w-full">
//     <video className="rounded-[20px] border-[2px] border-[#0093dd]" src={videoSource} type="video/mp4" width="600" controls></video>
// </div>
    useScrollAnimation();

    const showData = coreValuesData.map((el, index) =>

        <>
            <div key={index} className="width-100 js-scroll fade-top bgLinearTwo w-[22%] p-5 flex flex-col items-center justify-center rounded-lg h-[250px] gap-5">
                <div className="bg-[rgb(0_147_221_/_20%)] w-[100px] h-[100px] flex items-center justify-center rounded-full">{el.icons}</div>
                <div className="text-[22px] font-bold capitalize text-center text-white">
                    {t(`${el.title}`)}
                </div>
            </div>
        </>
    )
    return (
        <>
            <section className="hearder-pages relative border-b-4 border-b-[#0093dd]">
                <div className="btmAni30002 h-full text-white flex items-center justify-center f-title text-5xl font-bold uppercase relative gap-[20px]">
                    <div className="flex flex-col gap-[30px]">
                        <span className="ang-1 w-[20px] h-[20px] flex bg-transparent border-t border-l border-t-[1px] border-l-[1px] border-transparent border-t-white border-l-white"></span>
                        <span className="ang-2 w-[20px] h-[20px] flex bg-transparent border-b border-b border-b-[1px] border-l-[1px] border-transparent border-b-white border-l-white"></span>
                    </div>
                    {t("About Us")}
                    <div className="flex flex-col gap-[30px]">
                        <span className="ang-3 w-[20px] h-[20px] flex bg-transparent border-t border-r border-t-[1px] border-r-[1px] border-transparent border-t-white border-r-white"></span>
                        <span className="ang-4 w-[20px] h-[20px] flex bg-transparent border-b border-r border-b-[1px] border-r-[1px] border-transparent border-b-white border-r-white"></span>
                    </div>
                </div>
                <div className="d-none animtion-pages absolute -left-[300px] -top-[36px] transition-all duration-500">
                    <img src={require("../../assest/images/animation.png")} alt="" />
                </div>
            </section>
            <section className="relative py-[40px]">
                <div className="container flex flex-col gap-[30px]">
                    <div className="btmAni30002 text-white text-[42px] f-29 text-center font-bold">{t("About")} <span className="uppercase">{t('Hi-Tech')}</span> {t("Engineering")}</div>
                    <div className="flex flex-col gap-[50px]">
                        <div className="border-left-none js-scroll fade-right font-bold text-white text-[42px] f-29 uppercase border-l-[12px] border-l-[#0093dd] pl-[10px]">{t("Company Overview")}</div>
                        <div className="js-scroll fade-left padding-right-10 text-white font-light text-[25px] pl-[10px]">
                            {t("about1")}
                        </div>
                    </div>
                    <div className="flex flex-col gap-[50px]">
                        <div className="border-left-none js-scroll fade-right font-bold text-white text-[42px] f-29 uppercase border-l-[12px] border-l-[#0093dd] pl-[10px]">{t("Mission Statement")}</div>
                        <div className="js-scroll fade-left padding-right-10 text-white font-light text-[25px] pl-[10px]">
                            {t("about2")}
                        </div>
                    </div>
                    <div className="f-col js-scroll fade-right flex items-center justify-between gap-[50px]">
                        {showData}
                    </div>
                    <div className="flex flex-col gap-[50px]">
                        <div className="border-left-none js-scroll fade-left font-bold text-white text-[42px] f-29 uppercase border-l-[12px] border-l-[#0093dd] pl-[10px]">{t("History")}</div>
                        <div className="js-scroll fade-right padding-right-10 text-white font-light text-[25px] pl-[10px]">
                            {t("about3")}
                        </div>
                    </div>
                    <div className="flex flex-col gap-[50px]">
                        <div className="border-left-none js-scroll fade-left font-bold text-white text-[42px] f-29 uppercase border-l-[12px] border-l-[#0093dd] pl-[10px]">{t("Our Team")}</div>
                        <div className="js-scroll fade-right padding-right-10 text-white font-light text-[25px] pl-[10px]">
                            {t("about4")}
                        </div>
                    </div>
                    <TechnicalSupport />
                </div>
            </section>

        </>
    );
}