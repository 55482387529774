import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import partnersData from "../../data/partnersData";
import useScrollAnimation from "../../../hooks/useScrollAnimation";
export default function Partners() {
    const { t } = useTranslation();

    useScrollAnimation();

    const showData = partnersData.map((el, index) =>
        <div key={index} className="ij width-100 w-[30%] flex flex-col gap-[5px]" >
            <div className="text-[22px] text-white font-bold capitalize">{el.title}</div>
            <div>
                <img src={el.image} alt="" />
            </div>
            <Link className="text-white underline" to={el.url}>{el.urlName}</Link>
        </div >)

    return (
        <section className="relative py-[40px]">
            <div className="js-scroll fade-top main-title">{t("Our Partners")}</div>
            <div className="container flex flex-col items-center gap-[70px] justify-between">
                <div className="js-scroll fade-btm  text-white font-light text-[25px] text-center">
                    {t("Partners content")}
                </div>
                <div className="js-scroll fade-btm bgLinearTwo relative flex flex-col flex-wrap items-center justify-between gap-[20px] border-4 border-[#0077B6] w-full p-[20px] rounded-[20px]">
                    <div className="bg-[#111213] border-4 border-[#0077B6] relative top-[-68px] px-[35px] rounded-sm">
                        <img src={require("../../../assest/logo/logo.svg").default} alt="" />
                    </div>
                    <div className="flex flex-wrap justify-between items-center gap-[30px]">
                        {showData}
                    </div>
                </div>
            </div>
        </section>
    );
}