import React from "react";
import { useTranslation } from 'react-i18next';
import technicalSupportData from "../data/technicalSupportData";
import { Link } from "react-router-dom";
import useScrollAnimation from "../../hooks/useScrollAnimation";
import { Experts } from "../../assest/icons/icons";
export default function TechnicalSupport() {
    const { t } = useTranslation();
    useScrollAnimation();
    const tecData = technicalSupportData.map((el,index) =>
        <>
            <div key={index} className="width-100 js-scroll fade-btm width-100 bgLinearTwo w-[22%] p-5 flex flex-col items-center justify-center rounded-lg h-[250px] gap-5">
                <div className="bg-[rgb(0_147_221_/_20%)] w-[100px] h-[100px] flex items-center justify-center rounded-full">{el.icons}</div>
                <div className="text-[22px] font-bold capitalize text-center text-white">
                    {t(`${el.title}`)}
                </div>
            </div>
        </>

    )
    return (
        <>
            <section className="relative z-10 py-[40px]">
                <div className="container flex flex-col items-center text-center gap-[30px]">
                    <div className="f-col flex flex-col items-center gap-[50px]">
                        <div className="js-scroll fade-top f-34 text-white text-[42px] f-29 text-center font-bold">{t("24/7 Technical Support")}</div>
                        <div className="js-scroll fade-btm f-20 text-white font-light text-[25px] text-centerca">
                            {t("Technical content")}
                        </div>
                        <div className="flex items-center flex-wrap justify-center gap-[50px]">
                            {tecData}
                        </div>
                        <Link to={"/experts"} className="width-100 js-scroll fade-top bgLinearFull flex w-[30%] p-2 items-center gap-3 text-[22px] font-bold text-white rounded-lg justify-evenly">
                            <Experts/>
                            <div>{t("Talk to an expert")}</div>
                        </Link>
                    </div>
                </div>
            </section>

        </>
    );
}