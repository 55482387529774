import React from "react";
import { useTranslation } from 'react-i18next';
import useScrollAnimation from "../../hooks/useScrollAnimation";
import countries from "../data/countries";
import { Link } from "react-router-dom";

export default function Experts() {
    const { t } = useTranslation();
    useScrollAnimation();

    return (
        <>
            <section className="hearder-pages relative border-b-4 border-b-[#0093dd]">
                <div className="btmAni30002 h-full text-white flex items-center justify-center f-title text-5xl font-bold uppercase relative gap-[20px]">
                    <div className="flex flex-col gap-[30px]">
                        <span className="ang-1 w-[20px] h-[20px] flex bg-transparent border-t border-l border-t-[1px] border-l-[1px] border-transparent border-t-white border-l-white"></span>
                        <span className="ang-2 w-[20px] h-[20px] flex bg-transparent border-b border-b border-b-[1px] border-l-[1px] border-transparent border-b-white border-l-white"></span>
                    </div>
                    {t("Talk to an expert")}
                    <div className="flex flex-col gap-[30px]">
                        <span className="ang-3 w-[20px] h-[20px] flex bg-transparent border-t border-r border-t-[1px] border-r-[1px] border-transparent border-t-white border-r-white"></span>
                        <span className="ang-4 w-[20px] h-[20px] flex bg-transparent border-b border-r border-b-[1px] border-r-[1px] border-transparent border-b-white border-r-white"></span>
                    </div>
                </div>
                <div className="d-none animtion-pages absolute -left-[300px] -top-[36px] transition-all duration-500">
                    <img src={require("../../assest/images/animation.png")} alt="" />
                </div>
            </section>
            <section className="relative py-[40px]">
                <div className="container flex flex-col gap-[20px]">
                    <div>
                        <div className="border-left-none btmAni30002 font-bold text-white text-[42px] f-29 uppercase border-l-[12px] border-l-[#0093dd] pl-[10px]">{t("welcome to Hi-Tech Engineering")}</div>
                        <p className="btmAni25002 padding-right-10 text-white font-light text-[25px] pl-[10px]">{t("ex content")}</p>
                    </div>
                    <form action="https://email.petra-ms.com/expert.php" method="POST">
                        <div className="flex flex-col gap-[20px]">
                            <div className="flex flex-col gap-[20px]">
                                <div className="js-scroll fade-left f-col g-15 flex items-center justify-between">
                                    <div className="js-scroll fade-left width-100 flex flex-col gap-[7px] w-[500px]">
                                        <label className="text-[20px] font-bold text-white capitalize">{t("First Name")}</label>
                                        <input
                                            className="outline-0 border border-[#0093DD] p-2 bg-transparent text-white w-full rounded-lg"
                                            type="text"
                                            placeholder={t("First Name")}
                                            name="first_name"
                                            required
                                        />
                                    </div>
                                    <div className="js-scroll fade-right width-100 flex flex-col gap-[7px] w-[500px]">
                                        <label className="text-[20px] font-bold text-white capitalize">{t("Last Name")}</label>
                                        <input
                                            className="outline-0 border border-[#0093DD] p-2 bg-transparent text-white w-full rounded-lg"
                                            type="text"
                                            placeholder={t("Last Name")}
                                            name="last_name"
                                            required
                                        />
                                    </div>
                                </div>
                                {/* Phone and Job Position */}
                                <div className="js-scroll fade-right f-col g-15 flex items-center justify-between">
                                    <div className="js-scroll fade-right width-100 flex flex-col gap-[7px] w-[500px]">
                                        <label className="text-[20px] font-bold text-white capitalize">{t("Email")}</label>
                                        <input
                                            className="outline-0 border border-[#0093DD] p-2 bg-transparent text-white w-full rounded-lg"
                                            type="email"
                                            placeholder={t("Email")}
                                            name="email"
                                            required
                                        />
                                    </div>
                                    <div className="width-100 flex flex-col gap-[7px] w-[500px]">
                                        <label className="text-[20px] font-bold text-white capitalize">{t("Phone Number")}</label>
                                        <input
                                            className="outline-0 border border-[#0093DD] p-2 bg-transparent text-white w-full rounded-lg"
                                            type="text"
                                            placeholder={t("Phone Number")}
                                            name="phone"
                                            required
                                        />
                                    </div>
                                </div>
                                {/* Age, Major */}
                                <div className="js-scroll fade-top f-col g-15 flex items-center justify-between">
                                    <div className="width-100 flex flex-col gap-[7px] w-[500px]">
                                        <label className="text-[20px] font-bold text-white capitalize">{t("Company")}</label>
                                        <input
                                            className="outline-0 border border-[#0093DD] p-2 bg-transparent text-white w-full rounded-lg"
                                            type="text"
                                            placeholder={t("Company")}
                                            name="company"
                                        />
                                    </div>
                                    <div className="js-scroll fade-left width-100 flex flex-col gap-[7px] w-[500px]">
                                        <label className="text-[20px] font-bold text-white capitalize">{t("The Job You Are Applying For")}</label>
                                        <input
                                            className="outline-0 border border-[#0093DD] p-2 bg-transparent text-white w-full rounded-lg"
                                            type="text"
                                            placeholder={t("The Job You Are Applying For")}
                                            name="job_title"
                                        />
                                    </div>
                                </div>
                                <div className="js-scroll fade-left width-100 flex flex-col gap-[7px]">
                                    <label className="text-[20px] font-bold text-white capitalize">{t("Country/Region (please select)")}</label>
                                    <select
                                        className="outline-0 border border-[#0093DD] p-2 bg-transparent text-white w-full rounded-lg"
                                        name="country"
                                    >
                                        {countries.map((country, index) => (
                                            <option className="bgLinearTwo text-black" key={index} value={country.name}>
                                                {t(`${country.name}`)}  {/* Now displaying the full country name */}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="flex flex-col justify-start gap-[20px]">
                                    <div className="f-col flex flex-col gap-[7px] w-[100%] h-[300px]">
                                        <label className="text-[20px] font-bold text-white capitalize">{t("Your Message")}</label>
                                        <textarea className="max-h-[300px] h-full h-250 outline-0 border border-[#0093DD] p-2 bg-transparent text-white w-full rounded-lg" placeholder={t("Your Message")} name="message"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-[20px]">
                                {/* Additional sections */}
                                <div className="text-white text-[20px]">{t("Are there any sectors you would you be interested in receiving information about from Hi-Tech Engineering?")}</div>
                                <div className="flex items-center gap-[10px] flex-wrap">
                                    <label class="text-white text-[20px] js-scroll fade-top flex items-center gap-[5px]">
                                        <input type="checkbox" name="sectors[]" class="form-checkbox text-blue-600 w-[25px] h-[25px]" value="Aviation Security" />
                                        <span>{t("Aviation Security")}</span>
                                    </label>
                                    <label class="text-white text-[20px] js-scroll fade-top flex items-center gap-[5px]">
                                        <input type="checkbox" name="sectors[]" class="form-checkbox text-blue-600 w-[25px] h-[25px]" value="Air Cargo" />
                                        <span>{t("Air Cargo")}</span>
                                    </label>
                                    <label class="text-white text-[20px] js-scroll fade-top flex items-center gap-[5px]">
                                        <input type="checkbox" name="sectors[]" class="form-checkbox text-blue-600 w-[25px] h-[25px]" value="Urban Security" />
                                        <span>{t("Urban Security")}</span>
                                    </label>
                                    <label className="text-white text-[20px] js-scroll fade-top flex items-center gap-[5px]">
                                        <input
                                            type="checkbox"
                                            name="sectors[]"
                                            value="Ports & Borders"
                                            className="form-checkbox text-blue-600 w-[25px] h-[25px]"
                                        />
                                        <span>{t("Ports & Borders")}</span>
                                    </label>
                                    <label className="text-white text-[20px] js-scroll fade-top flex items-center gap-[5px]">
                                        <input
                                            type="checkbox"
                                            name="sectors[]"
                                            value="Defence"
                                            className="form-checkbox text-blue-600 w-[25px] h-[25px]"
                                        />
                                        <span>{t('Defence')}</span>
                                    </label>
                                </div>
                                <div className="text-white text-[20px]">{t("Hi-Tech Engineering provides occasional updates to interested parties on its products and services within various market sectors. These communications may be opted out of at any time.")}</div>
                                <div>
                                    <label className="text-white text-[20px] js-scroll fade-top flex items-center gap-[5px]">
                                        <input
                                            type="checkbox"
                                            name="sectors[]"
                                            value="Yes, I would like to sign up"
                                            className="form-checkbox text-blue-600 w-[25px] h-[25px]"
                                        />
                                        <span>{t("Yes, I would like to sign up for further information on my chosen market sector(s) from Hi-Tech Engineering LLC (and its subsidiary companies).")}</span>
                                    </label>
                                </div>
                                <div className="text-white text-[20px]">
                                    {t("HI-TECH Engineering will need  to store and process the personal information submitted to fulfil your request. For more information on the storage and use of your data, please refer to our")}
                                    <Link className="px-[5px] text-[#0093dd]" to={"/privacy"}>{("Privacy Policy")}</Link>
                                    {t("or")}
                                    <Link className="px-[5px] text-[#0093dd]" to={"/contact"}>{t("Contact Us")}</Link> {t("for more information.")}
                                </div>

                            </div>
                            <div>
                                <button className="custom-btn btn-3">
                                    <span>{t("send")}</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );

}
