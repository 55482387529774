import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import blogData from "../../data/blogData";

export default function BlogSection() {
    const { t } = useTranslation();

    const showData = blogData.slice(0, 2).map((el, index) => (
        <div key={index} className="js-scroll fade-btm bgLinearTwo min-h-[615px] w-full sm:w-[48%] p-[35px] rounded-[8px] flex flex-col gap-[15px]">
            <div>
                {el.image ? (
                    <img src={el.image} alt={el.title} />
                ) : el.video ? (
                    <video width="600" controls>
                        <source src={el.video} type="video/mp4" />
                    </video>
                ) : null}
            </div>
            <div className="capitalize text-[20px] text-white">{t(`${el.title}`)}</div>
            <p className="text-white font-light">{t(`${el.para}`)}</p>
            <div className="h-[2px] bg-white w-full"></div>
        </div>
    ));

    return (
        <section className="relative py-[40px]">
            <div className="js-scroll fade-top main-title text-center mb-[20px]">{t("Blogs And News")}</div>
            <div className="container flex flex-col items-center gap-[50px]">
                <div className="flex flex-wrap items-center justify-center gap-[20px] w-full">
                    {showData}
                </div>
                <Link className="js-scroll fade-top custom-btn btn-3 capitalize text-center capitalize" to="/blog">
                    <span>{t("Show more")}</span>
                </Link>
            </div>
        </section>
    );
}
