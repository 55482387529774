import React from "react";
import { useTranslation } from 'react-i18next';
import productData from "../../data/productData";
import { Link } from "react-router-dom";
export default function ProductSection() {
    const { t } = useTranslation();

    const dataShow = productData.slice(0, 4).map((el, index) =>
        <div key={index} className="width-100 f-col height-100 bgLinear w-[48%] h-[350px] p-[20px] rounded-[8px] flex gap-[12px]">
            <div className="width-100 w-[60%] h-full flex flex-col justify-center gap-[15px] ">
                <div className="text-[25px] text-white font-bold">{t(`${el.title}`)}</div>
                <div className="text-[20px] text-white">{t(`${el.subTitle}`)}</div>
                <p className="text-white text-[15px]">{t(`${el.description}`)}</p>
                <Link
                    className="js-scroll fade-top custom-btn btn-3 capitalize text-center capitalize"
                    to={`/details/${el.id}`}>
                    <span>{t("Show more")}</span>
                </Link>
            </div>
            <div className="width-100 w-[40%] flex items-center justify-center">
                <img className="w-full" src={el.images[0]} alt="" />
            </div>
        </div>)
    return (
        <section className="relative py-[40px]">
            <div className="js-scroll fade-top main-title">{t("Products")}</div>
            <div className="container flex flex-col items-center gap-[50px] justify-between">
                <div className="flex flex-wrap items-center justify-center gap-[20px]">
                    {dataShow}
                </div>
                <Link className="js-scroll fade-top custom-btn btn-3 capitalize text-center capitalize" to="/products">
                    <span>{t("Show more")}</span>
                </Link>
            </div>
        </section> 
    );
}