import React from "react";
import { useTranslation } from 'react-i18next';
import portfolioData from "../data/portfolioData";
import useScrollAnimation from "../../hooks/useScrollAnimation";
export default function Portfolio() {

    const { t } = useTranslation();

    useScrollAnimation();

    const showData = portfolioData.map((el,index) =>
        <div key={index} className="js-scroll fade-top width-100 bgLinearTwo rounded-[8px] w-[31%] flex flex-col gap-[20px] p-[20px] h-auto">
            <div className="w-full">
                <img className="w-full" src={el.image} alt="" />
            </div>
            <div className="flex flex-col gap-[10px]">
                <div className="font-bold text-[20px] capitalize text-white">
                    {t(`${el.title}`)}
                </div>
                <div className="flex flex-col gap-[5px]">
                    <div className="text-white capitalize text-[16px] font-bold">{t("Project Scope")}:</div>
                    <p className="text-white font-light">
                        {t(`${el.description}`)}
                    </p>
                </div>
            </div>
        </div>)
    return (
        <>
            <section className="hearder-pages relative border-b-4 border-b-[#0093dd]">
                <div className="btmAni30002 h-full text-white flex items-center justify-center f-title text-5xl font-bold uppercase relative gap-[20px]">
                    <div className="flex flex-col gap-[30px]">
                        <span className="ang-1 w-[20px] h-[20px] flex bg-transparent border-t border-l border-t-[1px] border-l-[1px] border-transparent border-t-white border-l-white"></span>
                        <span className="ang-2 w-[20px] h-[20px] flex bg-transparent border-b border-b border-b-[1px] border-l-[1px] border-transparent border-b-white border-l-white"></span>
                    </div>
                        {t("portfolio")}
                    <div className="flex flex-col gap-[30px]">
                        <span className="ang-3 w-[20px] h-[20px] flex bg-transparent border-t border-r border-t-[1px] border-r-[1px] border-transparent border-t-white border-r-white"></span>
                        <span className="ang-4 w-[20px] h-[20px] flex bg-transparent border-b border-r border-b-[1px] border-r-[1px] border-transparent border-b-white border-r-white"></span>
                    </div>
                </div>
                <div className="d-none animtion-pages absolute -left-[300px] -top-[36px] transition-all duration-500">
                    <img src={require("../../assest/images/animation.png")} alt="" />
                </div>
            </section>
            <section className="relative py-[40px]">
                <div className="container flex flex-col items-center gap-[50px]">
                    <div className="btmAni30002 text-[30px] font-bold capitalize text-white">{t("Notable Projects")}</div>
                    <div className="f-col flex flex-wrap gap-[20px] justify-center">
                        {showData}
                    </div>
                </div>
            </section>
        </>
    );
}