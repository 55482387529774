import React from "react";
import { useTranslation } from 'react-i18next';
import useScrollAnimation from "../../hooks/useScrollAnimation";
export default function Privacy() {
    const { t } = useTranslation();
    useScrollAnimation();
    return (
        <>
            <section className="hearder-pages relative border-b-4 border-b-[#0093dd]">
                <div className="btmAni30002 h-full text-white flex items-center justify-center text-5xl font-bold uppercase relative gap-[20px]">
                    <div className="flex flex-col gap-[30px]">
                        <span className="ang-1 w-[20px] h-[20px] flex bg-transparent border-t border-l border-t-[1px] border-l-[1px] border-transparent border-t-white border-l-white"></span>
                        <span className="ang-2 w-[20px] h-[20px] flex bg-transparent border-b border-b border-b-[1px] border-l-[1px] border-transparent border-b-white border-l-white"></span>
                    </div>
                    {t("Privacy Policy")}
                    <div className="flex flex-col gap-[30px]">
                        <span className="ang-3 w-[20px] h-[20px] flex bg-transparent border-t border-r border-t-[1px] border-r-[1px] border-transparent border-t-white border-r-white"></span>
                        <span className="ang-4 w-[20px] h-[20px] flex bg-transparent border-b border-r border-b-[1px] border-r-[1px] border-transparent border-b-white border-r-white"></span>
                    </div>
                </div>
                <div className="d-none animtion-pages absolute -left-[300px] -top-[36px] transition-all duration-500">
                    <img src={require("../../assest/images/animation.png")} alt="" />
                </div>
            </section>
            <section className="relative py-[40px]">
                <div className="container flex flex-col gap-[20px]">
                    <div className="flex flex-col gap-[20px]">
                        <div className="border-left-none btmAni25002 font-bold text-white text-[42px] f-29 uppercase border-l-[12px] border-l-[#0093dd] pl-[10px]">{t("Privacy Policy")}</div>
                        <div className="js-scroll fade-left padding-right-10 text-white font-light text-[25px] pl-[10px]">
                            {t("pri1")}
                        </div>
                        <div className="js-scroll fade-right padding-right-10 text-white font-light text-[25px] pl-[10px]">
                            {t("pri2")}
                        </div>
                        <div className="js-scroll fade-left padding-right-10 text-white font-light text-[25px] pl-[10px]">
                            {t("pri3")}
                        </div>
                        <div className="js-scroll fade-right padding-right-10 text-white font-light text-[25px] pl-[10px]">
                            {t("pri4")}
                        </div>
                        <div className="padding-right-10 text-white font-light text-[25px] pl-[10px] flex flex-col gap-[10px]">
                            <span className="js-scroll fade-left">{t("Office for Data Protection Compliance")}</span>
                            <span className="js-scroll fade-right">{t("Hi-Tech Engineering")} </span>
                            <span className="js-scroll fade-left flex items-center gap-[5px]">{t("Line 1 :")}
                                <span className="dir">+962 775531555</span>
                            </span>
                            <span className="js-scroll fade-left flex items-center gap-[5px]">{t("F:")}
                                <span className="dir">+962 6 585 4792</span>
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[20px]">
                    <div className="border-left-none js-scroll fade-right font-bold text-white text-[42px] f-29 uppercase border-l-[12px] border-l-[#0093dd] pl-[10px]">{t("WHAT PERSONAL DATA DO YOU COLLECT FROM ME AND USE?")}</div>
                        <div className="js-scroll fade-left padding-right-10 text-white font-light text-[25px] pl-[10px]">
                            {t("We collect, store, and use the following categories and types of data which identify you or can be used to identify you:")}
                        </div>
                        <div className="js-scroll fade-right js-scroll fade-left padding-right-10 text-white font-light text-[25px] pl-[10px]">
                            {t("Identification")}
                        </div>
                        <div className="js-scroll fade-left padding-right-10 text-white font-light text-[25px] pl-[10px]">
                            {t("When you visit")}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}