import React from "react";
import { useTranslation } from "react-i18next";
import productData from "../data/productData";
import { Link } from "react-router-dom";

export default function Product() {
    const { t } = useTranslation();

    // State to manage the active category
    // const [activeCategory, setActiveCategory] = useState("All");

    // Function to handle category selection
    // const handleCategoryChange = (category) => {
    //     setActiveCategory(category);
    // };

    // Filter products based on the active category
    // const filteredProducts = activeCategory === "All"
    //     ? productData
    //     : productData.filter(product => product.category === activeCategory);

    // Generate the filtered product list
    const dataShow = productData.map((el, index) => (
        <div key={index} className="width-100 f-col height-100 bgLinear w-[48%] h-[350px] p-[20px] rounded-[8px] flex gap-[12px]">
            <div className="width-100 w-[60%] h-full flex flex-col justify-center gap-[15px] ">
                <div className="text-[25px] text-white font-bold">{t(`${el.title}`)}</div>
                <div className="text-[20px] text-white">{t(`${el.subTitle}`)}</div>
                <p className="text-white text-[15px]">{t(`${el.description}`)}</p>
                <Link
                    className="custom-btn btn-3 capitalize text-center capitalize"
                    to={`/details/${el.id}`}>
                    <span>{t("Show more")}</span>
                </Link>
            </div>
            <div className="width-100 w-[40%] flex items-center justify-center">
                <img className="w-full" src={el.images[1]} alt="" />
            </div>
        </div>
    ));

    // List of categories
    // const categories = [
    //     "All",
    //     "X-RAY SCREENING",
    //     "Physical Barriers",
    //     "Detection & EOD Products",
    //     "Surveillance Technology",
    //     "Physical Access Control Systems",
    //     "Monitoring and Analysis Software"
    // ];

    return (
        <>
            <section className="hearder-pages relative border-b-4 border-b-[#0093dd]">
                <div className="btmAni30002 h-full text-white flex items-center justify-center f-title text-5xl font-bold uppercase relative gap-[20px]">
                    <div className="flex flex-col gap-[30px]">
                        <span className="ang-1 w-[20px] h-[20px] flex bg-transparent border-t border-l border-t-[1px] border-l-[1px] border-transparent border-t-white border-l-white"></span>
                        <span className="ang-2 w-[20px] h-[20px] flex bg-transparent border-b border-b border-b-[1px] border-l-[1px] border-transparent border-b-white border-l-white"></span>
                    </div>
                    {t("Products")}
                    <div className="flex flex-col gap-[30px]">
                        <span className="ang-3 w-[20px] h-[20px] flex bg-transparent border-t border-r border-t-[1px] border-r-[1px] border-transparent border-t-white border-r-white"></span>
                        <span className="ang-4 w-[20px] h-[20px] flex bg-transparent border-b border-r border-b-[1px] border-r-[1px] border-transparent border-b-white border-r-white"></span>
                    </div>
                </div>
                <div className="d-none animtion-pages absolute -left-[300px] -top-[36px] transition-all duration-500">
                    <img src={require("../../assest/images/animation.png")} alt="" />
                </div>
            </section>
            <section className="relative py-[40px]">
                <div className="container flex items-center justify-between flex-wrap gap-[20px]">
                    {/* Category Buttons */}
                    {/* <div className="flex gap-4 flex-wrap">
                        {categories.map((category, index) => (
                            <button
                                key={index}
                                className={`px-4 py-2 bg-white font-bold text-black capitalize rounded ${activeCategory === category ? "bgLinearFull font-bold capability text-white" : "bg-white"}`}
                                onClick={() => handleCategoryChange(category)}
                            >
                                {category}
                            </button>
                        ))}
                    </div> */}
                    {/* Filtered Products */}
                    <div className="flex flex-wrap gap-4 justify-start">
                        {dataShow}
                    </div>
                </div>
            </section>
        </>
    );
}
