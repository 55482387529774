import React from "react";
import { useTranslation } from 'react-i18next';
import clientsData from "../data/clientsData";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import useScrollAnimation from "../../hooks/useScrollAnimation";

export default function ClientsAndReferences() {
    const { t } = useTranslation();

    useScrollAnimation();

    const settings = {
        dots: false,
        infinite: true,
        speed: 300, // Increased speed (in milliseconds)
        autoplay: true, // Enable autoplay
        autoplaySpeed: 2000, // Speed of autoplay (in milliseconds, 2000ms = 2 seconds)
        slidesToShow: 4, // Show 6 items at a time
        slidesToScroll: 1,
        arrows: false, // Disable next and prev buttons
        customPaging: (i) => (
            <div
                className="w-[12px] h-[12px] rounded-full bg-[#0093dd] cursor-pointer hover:bg-white transition-all"
                style={{
                    margin: "0 5px",
                }}
            />
        ),
        appendDots: (dots) => (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                }}
            >
                {dots}
            </div>
        ),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                },
            },
        ],
    };

    return (
        <section className="relative py-[40px]">
            <div className="js-scroll fade-top main-title">{t("Our Clients & References")}</div>
            <div className="container flex flex-col items-center gap-[70px] justify-between">
                <div className="js-scroll fade-btm text-white font-light text-[25px] text-center">
                    {t("Clients & References content")}
                </div>
                {/* Slick Carousel */}
                <div className="js-scroll fade-bottom slick-carousel-container w-full">
                    <Slider {...settings}>
                        {clientsData.map((el, index) => (
                            <div
                                key={index}
                                className="flex items-center justify-center h-full p-[15px]" // Add padding for spacing
                            >
                                <div className="w-[275px] h-[275px] bgLinearTwo flex items-center justify-center rounded-[10px] h-auto p-[20px]">
                                    <img 
                                        src={el.image}
                                        alt={`Client ${index + 1}`}
                                        className="w-[225px] h-[225px] "
                                    />
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
                <Link className="js-scroll fade-top custom-btn btn-3 capitalize text-center capitalize" to="/client">
                    <span>{t("Show more")}</span>
                </Link>
            </div>
        </section>
    );
}
