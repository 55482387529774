/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { useTranslation } from "react-i18next";
import {
    PhoneIconContact,
    EmailIconContact,
    LocationIconContact,
    ArrowContact,
} from "../../assest/icons/icons";
import { useState } from "react";
import { Link } from "react-router-dom";
import useScrollAnimation from "../../hooks/useScrollAnimation";

export default function Contact() {
    const { t } = useTranslation();

    // eslint-disable-next-line no-unused-vars
    const [formData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
    });

    const [, setError] = useState("");
    const [, setSuccess] = useState(false);

    useScrollAnimation();


    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        setSuccess(false);
    
        try {
            const formData = {
                name: e.target.name.value,
                email: e.target.email.value,
                phone: e.target.phone.value,
                message: e.target.message.value,
            };
    
            const response = await fetch("https://email.petra-ms.com/contact.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: new URLSearchParams(formData),
            });
    
            const result = await response.text();
    
            if (response.ok) {
                setSuccess(true);
                setTimeout(() => {
                    // Redirecting to external site using window.location.href
                    window.location.href = "https://hitechdetection.com/"; // Redirect to the external website
                }, 1000); // Adding a delay before navigating
            } else {
                throw new Error(result || t("Failed to submit the form."));
            }
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <>
            <section className="hearder-pages relative border-b-4 border-b-[#0093dd]">
                <div className="btmAni30002 h-full text-white flex items-center justify-center f-title text-5xl font-bold uppercase relative gap-[20px]">
                    <div className="flex flex-col gap-[30px]">
                        <span className="ang-1 w-[20px] h-[20px] flex bg-transparent border-t border-l border-t-[1px] border-l-[1px] border-transparent border-t-white border-l-white"></span>
                        <span className="ang-2 w-[20px] h-[20px] flex bg-transparent border-b border-b border-b-[1px] border-l-[1px] border-transparent border-b-white border-l-white"></span>
                    </div>
                    {t("Contact Us")}
                    <div className="flex flex-col gap-[30px]">
                        <span className="ang-3 w-[20px] h-[20px] flex bg-transparent border-t border-r border-t-[1px] border-r-[1px] border-transparent border-t-white border-r-white"></span>
                        <span className="ang-4 w-[20px] h-[20px] flex bg-transparent border-b border-r border-b-[1px] border-r-[1px] border-transparent border-b-white border-r-white"></span>
                    </div>
                </div>
                <div className="d-none animtion-pages absolute -left-[300px] -top-[36px] transition-all duration-500">
                    <img src={require("../../assest/images/animation.png")} alt="" />
                </div>
            </section>
            <section className="relative py-[40px]">
                <div className="container flex flex-col gap-[50px]">
                    {/* Contact Information Section */}
                    <div className="f-col flex items-center justify-between gap-[20px]">
                        {/* Location */}
                        <div className="width-100 w-[30%] flex flex-col gap-[30px]">
                            <div className="btmAni30002 h-[320px] w-full bg-white p-5 flex flex-col items-center justify-center text-center rounded-lg gap-5">
                                <div className="bgLinearFull w-[90px] h-[90px] rounded-full border-[10px] border-[rgb(100_169_200)] flex items-center justify-center">
                                    <LocationIconContact />
                                </div>
                                <div className="flex flex-col gap-[10px]">
                                    <div className="capitalize text-[25px] text-[#0093DD]">{t("Location")}</div>
                                    <div>{t("Building 314 Zahran Street")}</div>
                                </div>
                            </div>
                            <Link to={"https://maps.app.goo.gl/2jBYTn6cBsjAC63f6"} className="btmAni25002 w-full text-[18px] bg-[#1AA1E0] flex items-center justify-center text-white font-bold gap-[10px] rounded-lg">
                                <ArrowContact />
                                {t("Contact")}
                            </Link>
                        </div>

                        {/* Phone */}
                        <div className="width-100 w-[30%] flex flex-col gap-[30px]">
                            <div className="btmAni20002 h-[320px] w-full bg-white p-5 flex flex-col items-center justify-center text-center rounded-lg gap-5">
                                <div className="bgLinearFull w-[90px] h-[90px] rounded-full border-[10px] border-[rgb(100_169_200)] flex items-center justify-center">
                                    <PhoneIconContact />
                                </div>
                                <div className="flex flex-col gap-[10px]">
                                    <div className="capitalize text-[25px] text-[#0093DD]">{t("Contact us by phone")}</div>
                                    <div className="flex flex-col gap-[5px]">
                                        <span>
                                            TEL : <Link className="underline" to={"tel:+962 6 5854791"}>+962-6-585-4791</Link>
                                        </span>
                                        <span>
                                            TEL : <Link className="underline" to={"tel:+962 775531555"}>+962-77-553-1555</Link>
                                        </span>
                                        <span>
                                            FAX : <Link className="underline" to={"tel:+962 6 585 4792"}>+962-6-585-4792</Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <Link to={"tel:+962 775531555"} className="btmAni3000 w-full bg-[#1AA1E0] text-[18px] flex items-center justify-center text-white font-bold gap-[10px] rounded-lg">
                                <ArrowContact />
                                {t("Contact")}
                            </Link>
                        </div>

                        {/* Email */}
                        <div className="width-100 w-[30%] flex flex-col gap-[30px]">
                            <div className="btmAni2500 h-[320px] w-full bg-white p-5 flex flex-col items-center justify-center text-center rounded-lg gap-5">
                                <div className="bgLinearFull w-[90px] h-[90px] rounded-full border-[10px] border-[rgb(100_169_200)] flex items-center justify-center">
                                    <EmailIconContact />
                                </div>
                                <div className="flex flex-col gap-[10px]">
                                    <div className="capitalize text-[25px] text-[#0093DD]">{t("Contact us by mail")}</div>
                                    <div>info@hitechjordan.com</div>
                                </div>
                            </div>
                            <Link to={"mailto:info@hitechjordan.com"} className="btmAni2000 w-full text-[18px] bg-[#1AA1E0] flex items-center justify-center text-white font-bold gap-[10px] rounded-lg">
                                <ArrowContact />
                                {t("Contact")}
                            </Link>
                        </div>
                    </div>

                    {/* Form Section */}
                    <form onSubmit={handleSubmit}>
                        <div className="flex flex-col gap-[20px]">
                            <div className="f-col flex justify-between">
                                <div className="flex flex-col gap-[20px]">
                                    <div className="js-scroll fade-left width-100 flex flex-col gap-[7px] w-[500px]">
                                        <label className="text-[20px] font-bold text-white capitalize">{t("Your Name")}</label>
                                        <input className="outline-0 border border-[#0093DD] p-2 bg-transparent text-white w-full rounded-lg" type="text" name="name" placeholder={t("Your Name")} required />
                                    </div>
                                    <div className="js-scroll fade-left width-100 flex flex-col gap-[7px] w-[500px]">
                                        <label className="text-[20px] font-bold text-white capitalize">{t("Your Email")}</label>
                                        <input className="outline-0 border border-[#0093DD] p-2 bg-transparent text-white w-full rounded-lg" type="email" name="email" placeholder={t("Your Email")} required />
                                    </div>
                                    <div className="js-scroll fade-left width-100 flex flex-col gap-[7px] w-[500px]">
                                        <label className="text-[20px] font-bold text-white capitalize">{t("Your phone number")}</label>
                                        <input className="outline-0 border border-[#0093DD] p-2 bg-transparent text-white w-full rounded-lg" type="text" name="phone" placeholder={t("Your phone number")} required />
                                    </div>
                                </div>
                                <div className="js-scroll fade-right width-100 flex flex-col gap-[7px] w-[500px]">
                                    <label className="text-[20px] font-bold text-white capitalize">{t("Your Message")}</label>
                                    <textarea className="max-h-[300px] h-full h-250 outline-0 border border-[#0093DD] p-2 bg-transparent text-white w-full rounded-lg" name="message" placeholder={t("Your Message")} required></textarea>
                                </div>
                            </div>
                            <button type="submit" className="custom-btn btn-3 capitalize text-center">
                                <span>{t("send")}</span>
                            </button>
                        </div>
                    </form>

                    <div className="js-scroll fade-btm w-full">
                        <iframe
                            className="rounded-[15px]"
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13540.731122103212!2d35.8607537!3d31.9559377!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151ca11283d2dcd3%3A0x76dd02c4f2df1661!2sZahran%20St%20314%2C%20Amman!5e0!3m2!1sen!2sjo!4v1732008801992!5m2!1sen!2sjo"
                            width="100%"
                            height="450"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                        ></iframe>
                    </div>
                </div>
            </section> 
        </>
    );
}
