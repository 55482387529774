import React from "react";
// import { useState } from "react";
// import { useEffect } from "react";
import AboutHomeSection from "../sections/Home/AboutHomeSection";
import ServicesHomeSection from "../sections/Home/ServicesHomeSection";
import TechnicalSupport from "../sections/TechnicalSupport";
import Partners from "../sections/Home/Partners";
import BlogSection from "../sections/Home/BlogSection";
import LandingPage from "../sections/LandingPage";
import ClientsAndReferences from "../sections/ClientsAndReferences";
import ProductSection from "../sections/Home/ProductSection";
import StatisticsSection from "../sections/Home/StatisticsSection";
// import Loader from "../Loader";
import useScrollToTop from '../../hooks/useScrollToTop';

export default function Home() {

    // const [loading, setLoading] = useState(true);

    useScrollToTop();

    // useEffect(() => {
    //     const timer = setTimeout(() => setLoading(false), 1000); // Simulate loading
    //     return () => clearTimeout(timer); // Cleanup
    // }, []);

    // if (loading) {
    //     return <Loader />;
    // }
    return (
        <section>
            <LandingPage />
            <AboutHomeSection />
            <ServicesHomeSection />
            <ClientsAndReferences />
            <StatisticsSection />
            <ProductSection />
            <TechnicalSupport />
            <Partners />
            <BlogSection />
        </section>
    );
}