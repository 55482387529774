import {
    Innovation,
    Integrity,
    Excellence,
    CustomerCommitment
} from "../../assest/icons/icons";

const coreValuesData = [
    {
        id: 1,
        icons:<Innovation/>,
        title: "Innovation",
    },
    {
        id: 2,
        icons:<Integrity/>,
        title: "Integrity",
    },
    {
        id: 3,
        icons:<Excellence/>,
        title: "Excellence",
    },
    {
        id: 4,
        icons:<CustomerCommitment/>,
        title: "Customer Commitment",
    },
    
];

export default coreValuesData;
