import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import {
    ArrowFooter,
    PhoneIcon,
    EmailIcon,
    LocationIcon,
    Facebook,
    X,
    Linkedin
} from "../../assest/icons/icons";
export default function Footer() {
    const { t } = useTranslation();
    return (
        <footer className="relative py-[20px] border-t-4 border-t-[#0093dd]">
            <div className="container flex flex-col gap-[30px]">
                <div className="f-col flex justify-between gap-[40px]">
                    <div className="width-100 flex flex-col gap-[30px] w-[30%]">
                        <Link>
                            <img src={require("../../assest/logo/logo.svg").default} alt="" />
                        </Link>
                        <p className="text-white">
                            {t("footer content")}
                        </p>
                    </div>
                    <div className="f-col width-100 w-[35%] flex justify-between gap-[25px]">
                        <div className="flex flex-col gap-[25px]">
                            <div className="text-white text-[25px] capitalize font-bold">{t("Quick Links")}</div>
                            <ul className="flex flex-col gap-[7px]">
                                <li>
                                    <Link className="flex items-center gap-[7px] text-white capitalize transition duration-500 hover:text-[#0093DD] text-[14px]" to={"/"}>
                                        <div className="transformRotate">
                                            <ArrowFooter />
                                        </div>
                                        {t("Home")}
                                    </Link>
                                </li>
                                <li>
                                    <Link className="flex items-center gap-[7px] text-white capitalize transition duration-500 hover:text-[#0093DD] text-[14px]" to={"/ceo"}>
                                        <div className="transformRotate">
                                            <ArrowFooter />
                                        </div>
                                        {t("our CEO")}
                                    </Link>
                                </li>
                                <li>
                                    <Link className="flex items-center gap-[7px] text-white capitalize transition duration-500 hover:text-[#0093DD] text-[14px]" to={"/about"}>
                                        <div className="transformRotate">
                                            <ArrowFooter />
                                        </div>
                                        {t("About Us")}
                                    </Link>
                                </li>
                                <li>
                                    <Link className="flex items-center gap-[7px] text-white capitalize transition duration-500 hover:text-[#0093DD] text-[14px]" to={"/services"}>
                                        <div className="transformRotate">
                                            <ArrowFooter />
                                        </div>
                                        {t("Services")}
                                    </Link>
                                </li>
                                <li>
                                    <Link className="flex items-center gap-[7px] text-white capitalize transition duration-500 hover:text-[#0093DD] text-[14px]" to={"/products"}>
                                        <div className="transformRotate">
                                            <ArrowFooter />
                                        </div>
                                        {t("Products")}
                                    </Link>
                                </li>
                                <li>
                                    <Link className="flex items-center gap-[7px] text-white capitalize transition duration-500 hover:text-[#0093DD] text-[14px]" to={"/portfolio"}>
                                        <div className="transformRotate">
                                            <ArrowFooter />
                                        </div>
                                        {t("portfolio")}
                                    </Link>
                                </li>
                                <li>
                                    <Link className="flex items-center gap-[7px] text-white capitalize transition duration-500 hover:text-[#0093DD] text-[14px]" to={"/client"}>
                                        <div className="transformRotate">
                                            <ArrowFooter />
                                        </div>
                                        {t("Clients & References")}
                                    </Link>
                                </li>
                                <li>
                                    <Link className="flex items-center gap-[7px] text-white capitalize transition duration-500 hover:text-[#0093DD] text-[14px]" to={"/contact"}>
                                        <div className="transformRotate">
                                            <ArrowFooter />
                                        </div>
                                        {t("Contact Us")}
                                    </Link>
                                </li>
                                <li>
                                    <Link className="flex items-center gap-[7px] text-white capitalize transition duration-500 hover:text-[#0093DD] text-[14px]" to={"/careers"}>
                                        <div className="transformRotate">
                                            <ArrowFooter />
                                        </div>
                                        {t("careers")}
                                    </Link>
                                </li>
                                <li>
                                    <Link className="flex items-center gap-[7px] text-white capitalize transition duration-500 hover:text-[#0093DD] text-[14px]" to={"/care"}>
                                        <div className="transformRotate">
                                            <ArrowFooter />
                                        </div>
                                        {t("We Care")}
                                    </Link>
                                </li>
                                <li>
                                    <Link className="flex items-center gap-[7px] text-white capitalize transition duration-500 hover:text-[#0093DD] text-[14px]" to={"/blog"}>
                                        <div className="transformRotate">
                                            <ArrowFooter />
                                        </div>
                                        {t("Blogs And News")}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="flex flex-col gap-[25px]">
                            <div className="text-white text-[25px] capitalize font-bold">{t("Contact Us")}</div>
                            <ul className="flex flex-col gap-[7px]">
                                <li>
                                    <Link className="flex items-center gap-[7px] text-white capitalize transition duration-500 hover:text-[#0093DD] text-[14px]" to={"tel:+962 775531555"}>
                                        <PhoneIcon />
                                        <span className="dir">+962 77 553 1555</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="flex items-center gap-[7px] text-white capitalize transition duration-500 hover:text-[#0093DD] text-[14px]" to={"mailto:info@hitechjordan.com"}>
                                        <EmailIcon />
                                        info@hitechjordan.com
                                    </Link>
                                </li>
                                <li>
                                    <Link className="flex items-center gap-[7px] text-white capitalize transition duration-500 hover:text-[#0093DD] text-[14px]" to={"https://maps.app.goo.gl/2jBYTn6cBsjAC63f6"}>
                                        <LocationIcon />
                                        {t("Building 314 Zahran Street")}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="width-100 w-[30%] flex flex-col gap-[25px]">
                        <div className="text-white text-[25px] capitalize font-bold">{t("We Care")}</div>
                        <div className="bg-white p-[10px] rounded-md flex items-center justify-between">
                            <div  className="flex items-center gap-[10px]">{t("Message us")} <div className="transformRotate">➡️</div></div>
                            <Link to={"/care"} className="bg-[#0093DD] p-[10px] rounded-[4px]">
                                <div className="transformRotate">
                                    <ArrowFooter />
                                </div>
                            </Link>
                        </div>
                        <div className="flex items-center gap-[10px]">
                            <Link to={"/"} className="bg-[#0093DD] w-[50px] h-[50px] rounded-[50px] flex items-center justify-center">
                                <Facebook />
                            </Link>
                            <Link to={"https://www.linkedin.com/in/hi-tech-engineering-llc-26310218/?originalSubdomain=jo"} className="bg-[#0093DD] w-[50px] h-[50px] rounded-[50px] flex items-center justify-center">
                                <Linkedin />
                            </Link>
                            <Link to={"/"} className="bg-[#0093DD] w-[50px] h-[50px] rounded-[50px] flex items-center justify-center">
                                <X />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="f-col flex justify-between items-center py-[10px] border-t-2 border-white">
                    <div className="font-bold text-white">
                        {t("Copyright")}
                    </div>
                    <div className="flex items-center gap-[15px]">
                        <Link className="font-bold text-white transition duration-500 hover:text-[#0093DD] text-[14px]" to={"/legal"}>{t("Legal")}</Link>
                        <div className="text-white">||</div>
                        <Link className="font-bold text-white transition duration-500 hover:text-[#0093DD] text-[14px]" to={"/terms"}>{t("Terms & Conditions")}</Link>
                        <div className="text-white">||</div>
                        <Link className="font-bold text-white transition duration-500 hover:text-[#0093DD] text-[14px]" to={"/privacy"}>{t("Privacy Policy")}</Link>
                    </div>
                </div>
            </div>
        </footer>
    );
}