/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Slider from "react-slick";

// Slick Carousel CSS import
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import sliderImage from "../data/sliderImage";

export default function LandingPage() {
    const { t } = useTranslation();

    // Slider settings
    const settings = {
        dots: true,
        infinite: true,
        speed: 300, // Increased speed (in milliseconds)
        autoplay: true, // Enable autoplay
        autoplaySpeed: 2000, // Speed of autoplay (in milliseconds, 2000ms = 2 seconds)
        slidesToShow: 1,  // Show 3 images
        slidesToScroll: 1,
        arrows: false, // Disable next and prev buttons
        customPaging: (i) => (
            <div
                className="w-[12px] h-[12px] rounded-full bg-[#0093dd] cursor-pointer hover:bg-white transition-all"
                style={{
                    margin: "0 5px",
                }}
            />
        ),
        appendDots: (dots) => (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                }}
            >
                {dots}
            </div>
        ),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <section className="landing-page relative py-[40px]">
            <div className="f-col container h-full flex items-center justify-between gap-[40px]">
                <div className="flex flex-col gap-[15px]">
                    <div className="btmAni30002 text-white text-[35px] font-bold capitalize">{t("Innovative Security & Engineering Solutions")}</div>
                    <p className="btmAni25002 text-white text-[20px]">
                        {t("Leading Provider of Advanced Technological Solutions for Security, Control Systems, and More")}
                    </p>
                    <Link className="btmAni20002 js-scroll fade-top custom-btn btn-3 capitalize text-center capitalize" to="/about">
                        <span>{t("Show more")}</span>
                    </Link>
                </div>

                {/* Slick Carousel */}
                <div className="btmAni3000 slick-carousel-container w-[40%]">
                    <Slider {...settings}>
                        {sliderImage.map((el, index) => (
                            <div key={index} className="">
                                <img
                                    src={el.image}
                                    alt={el.id}
                                    className="w-full h-auto"
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    );
}
