import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import useScrollAnimation from "../../../hooks/useScrollAnimation";

export default function AboutHomeSection(){
    const { t } = useTranslation();

    useScrollAnimation();
    return(
        <section className="relative py-[40px]">
            <div className="js-scroll fade-top main-title">{t("About Us")}</div>
            <div className="f-col container flex items-center gap-[50px] justify-between">
                <div className="js-scroll fade-left d-none w-[50%]">
                    <img className="w-[70%]" src={require("../../../assest/images/about-home-image.svg").default} alt="" />
                </div>
                <div className="js-scroll fade-right width-100 w-[50%] flex flex-col gap-[15px]">
                    <div className="text-white font-bold text-[35px]">{t("About")} <span className="uppercase">{t("Hi-Tech")}</span> {t("Engineering")}</div>
                    <p className="text-[20px] text-white font-light">
                       {t("about Hi")}
                    </p>
                    <Link className="btmAni20002 js-scroll fade-top custom-btn btn-3 capitalize text-center capitalize" to="/about">
                        <span>{t("Read more")}</span>
                    </Link>
                </div>
            </div>
        </section>
    );
}